import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import AdminLayout from 'src/layouts/AdminLayout';
// import HomeView from 'src/views/home/HomeView';
// import PrivacyView from 'src/views/home/PrivacyView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from 'src/components/AdminGuard';
// import PartnerGuard from 'src/components/PartnerGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    // guard: AuthGuard,
    path: '/partnerlink/:partnerId',
    component: lazy(() => import('src/views/auth/PartnerLink'))
  },
  //   {
  //   exact: true,
  //   // guard: GuestGuard,
  //   path: '/partnerlink',
  //   component: lazy(() => import('src/views/auth/PartnerLink'))
  // },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/verify',
    component: lazy(() => import('src/views/auth/EmailVerifyView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/register/:partnerId',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,

    path: '/get-started',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    layout: MainLayout,
    path: '/get-started-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    // guard: GuestGuard,
    path: '/reset',
    component: lazy(() => import('src/views/auth/ResetView'))
  },
  {
    guard: AdminGuard,
    layout: AdminLayout,
    path: '/admin',
    routes: [
      {
        exact: true,
        path: '/admin',
        component: lazy(() => import('src/views/admin/AdminView'))
      },
      {
        exact: true,
        path: '/admin/customers',
        component: lazy(() =>
          import('src/views/admin/customer/CustomerListView')
        )
      },
      {
        exact: true,
        path: '/admin/questions',
        component: lazy(() =>
          import('src/views/admin/question/QuestionListView')
        )
      },
      {
        exact: true,
        path: '/admin/questions/:questionId',
        component: lazy(() =>
          import('src/views/admin/question/QuestionDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/questions/:questionId/edit',
        component: lazy(() =>
          import('src/views/admin/question/QuestionEditView')
        )
      },
      {
        exact: true,
        path: '/admin/notifications',
        component: lazy(() =>
          import('src/views/admin/notifications/NotificationsListView')
        )
      },
      {
        exact: true,
        path: '/admin/notifications/:questionId',
        component: lazy(() =>
          import('src/views/admin/notifications/NotificationsDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/notifications/:questionId/edit',
        component: lazy(() =>
          import('src/views/admin/notifications/NotificationsEditView')
        )
      },
      {
        exact: true,
        path: '/admin/partners',
        component: lazy(() =>
          import('src/views/admin/partnerlinks/PartnerListView')
        )
      },
      {
        exact: true,
        path: '/admin/partners/:partnerId',
        component: lazy(() =>
          import('src/views/admin/partnerlinks/PartnerDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/partners/:partnerId/edit',
        component: lazy(() =>
          import('src/views/admin/partnerlinks/PartnerEditView')
        )
      },
      {
        exact: true,
        path: '/admin/applications',
        component: lazy(() =>
          import('src/views/admin/applications/ApplicationListView')
        )
      },
      {
        exact: true,
        path: '/admin/applications/:applicationId',
        component: lazy(() =>
          import('src/views/admin/applications/ApplicationDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/profiles',
        component: lazy(() =>
          import('src/views/admin/profiles/ProfileListView')
        )
      },
      {
        exact: true,
        path: '/admin/profiles/:profileId',
        component: lazy(() =>
          import('src/views/admin/profiles/ProfileDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/customers/:customerId',
        component: lazy(() =>
          import('src/views/admin/customer/CustomerDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/customers/:customerId/edit',
        component: lazy(() =>
          import('src/views/admin/customer/CustomerEditView')
        )
      },

      {
        exact: true,
        path: '/admin/invoices',
        component: lazy(() => import('src/views/admin/invoice/InvoiceListView'))
      },
      {
        exact: true,
        path: '/admin/invoices/:invoiceId',
        component: lazy(() =>
          import('src/views/admin/invoice/InvoiceDetailsView')
        )
      },
      {
        exact: true,
        path: '/admin/orders',
        component: lazy(() => import('src/views/admin/order/OrderListView'))
      },
      {
        exact: true,
        path: '/admin/orders/:orderId',
        component: lazy(() => import('src/views/admin/order/OrderDetailsView'))
      },
      {
        exact: true,
        path: '/admin/products',
        component: lazy(() => import('src/views/admin/product/ProductListView'))
      },
      {
        exact: true,
        path: '/admin/products/create',
        component: lazy(() =>
          import('src/views/admin/product/ProductCreateView')
        )
      },
      {
        exact: true,
        path: '/admin/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/admin/links',
        component: lazy(() => import('src/views/instructions/LinksView'))
      }
    ]
  },
  {
    guard: AuthGuard,
    layout: DashboardLayout,
    path: '/partner',
    routes: [
      {
        exact: true,
        path: '/partner/overview',
        component: lazy(() => import('src/views/partners/Overview'))
      },
      {
        exact: true,
        path: '/partner/management',
        component: lazy(() => import('src/views/partners/Overview/General'))
      },
      {
        exact: true,
        path: '/partner/application/:applicationId',
        component: lazy(() =>
          import('src/views/partners/applications/ApplicationDetailsView')
        )
      },
      {
        exact: true,
        path: '/partner/applications',
        component: lazy(() =>
          import('src/views/partners/applications/ApplicationListView')
        )
      },
      {
        exact: true,
        path: '/partner/profiles',
        component: lazy(() =>
          import('src/views/partners/profiles/ProfileListView')
        )
      }
      // {
      //   exact: true,
      //   path: '/partner/customers',
      //   component: lazy(() => import('src/views/admin/customer/CustomerListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/questions',
      //   component: lazy(() => import('src/views/admin/question/QuestionListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/questions/:questionId',
      //   component: lazy(() => import('src/views/admin/question/QuestionDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/questions/:questionId/edit',
      //   component: lazy(() => import('src/views/admin/question/QuestionEditView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/applications',
      //   component: lazy(() =>
      //     import('src/views/admin/applications/ApplicationListView')
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/partner/applications/:applicationId',
      //   component: lazy(() =>
      //     import('src/views/admin/applications/ApplicationDetailsView')
      //   )
      // },
      // {
      //   exact: true,
      //   path: '/partner/profiles',
      //   component: lazy(() => import('src/views/profiles/ProfileListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/profiles/:profileId',
      //   component: lazy(() => import('src/views/admin/profiles/ProfileDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/customers/:customerId',
      //   component: lazy(() => import('src/views/admin/customer/CustomerDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/customers/:customerId/edit',
      //   component: lazy(() => import('src/views/admin/customer/CustomerEditView'))
      // },

      // {
      //   exact: true,
      //   path: '/partner/invoices',
      //   component: lazy(() => import('src/views/admin/invoice/InvoiceListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/invoices/:invoiceId',
      //   component: lazy(() => import('src/views/admin/invoice/InvoiceDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/orders',
      //   component: lazy(() => import('src/views/admin/order/OrderListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/orders/:orderId',
      //   component: lazy(() => import('src/views/admin/order/OrderDetailsView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/products',
      //   component: lazy(() => import('src/views/admin/product/ProductListView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/products/create',
      //   component: lazy(() => import('src/views/admin/product/ProductCreateView'))
      // },
      // {
      //   exact: true,
      //   path: '/partner/management',
      //   component: () => <Redirect to="/app/management/customers" />
      // },
      // {
      //   exact: true,
      //   path: '/partner/links',
      //   component: lazy(() => import('src/views/instructions/LinksView'))
      // }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/dataroom',
        component: lazy(() => import('src/views/dataroom/AccountView'))
      },
      // {
      //   exact: true,
      //   path: '/app/accountnew',
      //   component: lazy(() => import('src/views/account/AccountViewNew'))
      // },
      {
        exact: true,
        path: '/app/instructions',
        component: lazy(() => import('src/views/instructions/InstructionView'))
      },
      {
        exact: true,
        path: '/app/assessment',
        component: lazy(() => import('src/views/riskassessment/RiskListView'))
      },
      // {
      //   exact: true,
      //   path: '/app/reportold',
      //   component: lazy(() => import('src/views/riskreport'))
      // },
      {
        exact: true,
        path: '/app/report',
        component: lazy(() => import('src/views/riskreportnew'))
      },
      {
        exact: true,
        path: '/app/share',
        component: lazy(() => import('src/views/shareapplication/ProfileView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },
      {
        exact: true,
        path: '/app/extra/charts/apex',
        component: lazy(() => import('src/views/extra/charts/ApexChartsView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/formik',
        component: lazy(() => import('src/views/extra/forms/FormikView'))
      },
      {
        exact: true,
        path: '/app/extra/forms/redux',
        component: lazy(() => import('src/views/extra/forms/ReduxFormView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/draft-js',
        component: lazy(() => import('src/views/extra/editors/DraftEditorView'))
      },
      {
        exact: true,
        path: '/app/extra/editors/quill',
        component: lazy(() => import('src/views/extra/editors/QuillEditorView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: [
          '/app/mail/label/:customLabel/:mailId?',
          '/app/mail/:systemLabel/:mailId?'
        ],
        component: lazy(() => import('src/views/mail/MailView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        exact: true,
        path: '/app/projects/overview',
        component: lazy(() => import('src/views/project/OverviewView'))
      },
      {
        exact: true,
        path: '/app/projects/browse',
        component: lazy(() => import('src/views/project/ProjectBrowseView'))
      },
      {
        exact: true,
        path: '/app/projects/create',
        component: lazy(() => import('src/views/project/ProjectCreateView'))
      },
      {
        exact: true,
        path: '/app/projects/:id',
        component: lazy(() => import('src/views/project/ProjectDetailsView'))
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports/dashboard',
        component: lazy(() => import('src/views/reports/DashboardView'))
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/account" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView'))
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView'))
      },
      {
        exact: true,
        path: '/docs/environment-variables',
        component: lazy(() => import('src/views/docs/EnvironmentVariablesView'))
      },
      {
        exact: true,
        path: '/docs/deployment',
        component: lazy(() => import('src/views/docs/DeploymentView'))
      },
      {
        exact: true,
        path: '/docs/api-calls',
        component: lazy(() => import('src/views/docs/APICallsView'))
      },
      {
        exact: true,
        path: '/docs/analytics',
        component: lazy(() => import('src/views/docs/AnalyticsView'))
      },
      {
        exact: true,
        path: '/docs/authentication',
        component: lazy(() => import('src/views/docs/AuthenticationView'))
      },
      {
        exact: true,
        path: '/docs/routing',
        component: lazy(() => import('src/views/docs/RoutingView'))
      },
      {
        exact: true,
        path: '/docs/settings',
        component: lazy(() => import('src/views/docs/SettingsView'))
      },
      {
        exact: true,
        path: '/docs/state-management',
        component: lazy(() => import('src/views/docs/StateManagementView'))
      },
      {
        exact: true,
        path: '/docs/theming',
        component: lazy(() => import('src/views/docs/ThemingView'))
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView'))
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },

  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('src/views/home/HomeView'))
      },
      {
        exact: true,
        path: '/contact',
        component: lazy(() => import('src/views/home/ContactView'))
      },
      {
        exact: true,
        path: '/privacy',
        component: lazy(() => import('src/views/home/PrivacyView'))
      },
      {
        exact: true,
        path: '/disclosures',
        component: lazy(() => import('src/views/home/Disclosures'))
      },
      {
        exact: true,
        path: '/terms-of-use',
        component: lazy(() => import('src/views/home/Terms'))
      },
      {
        exact: true,
        path: '/terms-of-service',
        component: lazy(() => import('src/views/home/TermsService'))
      },
      //{
      //  exact: true,
      //  path: '/philosophy',
      //  component: lazy(() => import('src/views/home/PhilosophyView'))
      //},
      {
        exact: true,
        path: '/pricing',
        component: lazy(() => import('src/views/pricing/PricingView'))
      },
      //{
      //  exact: true,
      //  path: '/team',
      //  component: lazy(() => import('src/views/home/TeamView'))
      //},
      //{  
      //  exact: true,
      //  path: '/team/:slug',
      //  component: lazy(() => import('src/views/home/TeamIndividualView'))
      //},
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
