export const APP_VERSION = '1.3';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  OSNEY: 'OSNEY'
};

// export const modulecolor = {
//   cyber: '#0079BF',
//   governance: '#70B500',
//   legal: '#FF9F1A',
//   asset: '#EB5A46',
//   people: '#F2D600',
//   mi: '#C377E0',
// };

export const modulecolor = {
  cyber: '#00aeff',
  governance: '#eea26f',
  legal: '#f6e25d',
  asset: '#b7b983',
  people: '#63b476',
  mi: '#0b6467'
};

export const partners = [
  {
    name: 'Paul Wilkes',
    first:'Paul',
    slug: 'paul-wilkes',
    image: '/static/images/team/paul/paul-hires@0.1x.png',
    twitter:'https://twitter.com/PaulWilkesVC',
    linkedin:'https://www.linkedin.com/in/paul-wilkes-vc/',
    medium:'https://medium.com/@paul.wilkes',
    bio:
      'Paul has over 15 years of experience as a financial services and regulatory lawyer, including eight years as a partner leading the corporate team at offshore law firm Collas Crill LLP, building and nurturing high-performing teams. He built a diverse practice including advising on venture capital fund and joint venture structuring, mergers and acquisitions, banking & finance and financial services regulation. Paul has an MBA from the University of Oxford, an LLB from the University of New South Wales and a first class honours degree in Physiotherapy from Sydney University.'
  },
  {
    name: 'Joshua Walter',
    first:'Joshua',
    slug: 'joshua-walter',
    image: '/static/images/team/joshua/joshua-hires@0.1x.png',
    twitter:'https://twitter.com/PaulWilkesVC',
    linkedin:'https://www.linkedin.com/in/joshua-walter-54011626/',
    medium:'https://medium.com/@paul.wilkes',
    bio:
      "Josh has held numerous Senior Leadership roles in the Cyber Security and Financial Services sectors at two leading firms in their respective industries; NCC Group and Barclays Bank. Josh's career has primarily focused on growth; defining and executing go-to-market strategies, leading high-performing Enterprise Sales teams and leading global growth initiatives. Josh has an MBA from the University of Oxford, and a first class honours degree in Civil Engineering from Birmingham University."
  },
  {
    name: 'Adam Cragg',
    first:'Adam',
    slug: 'adam-cragg',
    image: '/static/images/team/adam/adam-hires@0.1x.png',
    twitter:'https://twitter.com/adamcragg',
    linkedin:'https://www.linkedin.com/in/adamcragg/',
    medium:'https://blog.adamcragg.com/',
    bio:
      'Adam has served as the Chief of Staff of the MIT Entrepreneurship Center, the Founding Manager of the NYU Entrepreneurship Lab, and was a Founding Partner of Quake Capital a seed-stage investor and accelerator. Quake made Forbes’ list of the top ten VCs globally. Adam has invested in over one hundred and thirty start-up businesses at seed stage, with an audited IRR of 23%, and brings a wealth of experience in supporting businesses through their early years. Adam has an MBA from the University of Oxford.'
  }
];

export const sras = [
  {
    name: 'Ravi Wesley',
    image: '/static/images/team/ravi/ravi@0.1x.png',
    sub: 'Supply Chain Technology, Automation',
    bio:
      'Having advised some of the largest corporations globally on their digital transformation and innovation strategies, Ravi offers deep insights and advice in the areas of supply chain technology, robotics and automation, and enterprise technology platforms.'
  },
  {
    name: 'Christine Li-Auyeung',
    image: '/static/images/team/christine/christine@0.5x.png',
    sub: 'FinTech Operations, Rapid Scaling',
    bio:
      'An operator with over a decade of experience scaling up operations and developing products at FinTech companies – taking them from early stage startups to the hypergrowth phase.'
  },
  {
    name: 'Stephanie Gnissios',
    image: '/static/images/team/stephanie/stephanie@0.1x.png',
    sub: 'Operations, ESG',
    bio:
      'Having built physical operations in several geographies, Stephanie is expert in execution and de-risking the success of scaling at pace, with a particular focus on ESG requirements. '
  },
  {
    name: 'Jane Wainwright',
    image: '/static/images/team/jane.png',
    sub: 'Cyber & Privacy, Transformation',
    bio:
      'A highly respected leader in the Cyber and Privacy space. In addition to guiding global organizations through the world of Data Protection regulation and risk management, Jane has deep experience on leading broader transformation and major programmes. '
  },
  {
    name: 'Sean Lloyd',
    image: '/static/images/team/sean/Sean@0.5x.png',
    sub: 'Government Affairs',
    bio:
      'A risk management professional and former Australian diplomat with over a decade of experience managing relations between government, industry and civil society in challenging political and regulatory environments.'
  },
  {
    name: 'Ankur Gupta',
    image: '/static/images/team/ankur/ankur@0.1x.png',
    sub: 'Big Data, AI',
    bio:
      'Ankur has deep experience in integrating Big Data and AI platforms into businesses of all sizes ensuring optimal use of these technological enablers. '
  },
  {
    name: 'Fernando Montera Filho',
    image: '/static/images/team/fernando/fernando@0.1x.png',
    sub: 'Product',
    bio:
      'With several highly successful exits under his belt, Fernando understands customer expectations of technical products, and how to de-risk product lifecycles.'
  },
  {
    name: 'Aliya Mohamed',
    image: '/static/images/team/aliya/aliya@0.1x.png',
    sub: 'Comms & PR',
    bio:
      'Having worked side-by-side with the former Prime Minister of Canada, Aliya helps startups navigate their most sensitive communications and brand challenges '
  },
  {
    name: 'Poweide Koroye',
    image: '/static/images/team/poweide/poweide@0.1x.png',
    sub: 'Finance, Accounting',
    bio:
      'Poweide is a chartered accountant with more than 20 years’ experience in finance roles spanning financial operations, planning, analysis and reporting. Poweide is skilled at creating and executing strategic plans and adept at building relationships, using highly developed influencing and communication skills. He has a project management background that includes major system implementations (e.g. SAP and PeopleSoft) and process improvement projects. As well as experience in risk management, and merger and acquisition investigations, he is familiar with emerging markets having lived and worked in several of them, mostly in Africa and South Asia.'
  },
  {
    name: 'Rory Berke',
    image: '/static/images/team/rory/rory@0.1x.png',
    sub: 'People, Globalisation',
    bio:
      'Previously an instructor at the U.S. Naval Academy, Rory specialises in high performance teams, with a unique appreciation of global expansion and international operations.'
  }
];
// labels: ['Cyber & Data Privacy', 'Corporate Governance', 'Legal', 'Asset', 'People', 'MI & Operational'],
//       colors: ['#0079BF','#70B500','#FF9F1A','#EB5A46','#F2D600','#C377E0'],
