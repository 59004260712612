// import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
// import firebase from 'src/lib/firebase';
import React, { useState } from 'react';
// import useIsMountedRef from 'src/hooks/useIsMountedRef';

// import {
// Briefcase as BriefcaseIcon,
// Calendar as CalendarIcon,
// ShoppingCart as ShoppingCartIcon,
// Folder as FolderIcon,
// BarChart as BarChartIcon,
// Lock as LockIcon,
// List as ListIcon,
// UserPlus as UserPlusIcon,
// AlertCircle as AlertCircleIcon,
// Trello as TrelloIcon,
// User as UserIcon,
// Layout as LayoutIcon,
// Edit as EditIcon,
// DollarSign as DollarSignIcon,
// Mail as MailIcon,
// MessageCircle as MessageCircleIcon,
// PieChart as PieChartIcon,
// Share2 as ShareIcon,
// Users as UsersIcon,
// Activity as ActivityIcon,
// Clipboard as ClipboardIcon,
// HelpCircle as HelpCircleIcon,
// Activity as ActivityIcon,
// HelpCircle as HelpCircleIcon,
// Globe as GlobeIcon,
// Smile as SmileIcon,
// Package as PackageIcon,
// Link as LinkIcon
// } from 'react-feather';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  // const isMountedRef = useIsMountedRef();
  // const [sections, setSections] = useState([
  //   {
  //     subheader: 'Founder Dashboard',
  //     items: [
  //       {
  //         title: 'Instructions',
  //         icon: ListIcon,
  //         href: '/app/instructions'
  //       },
  //       {
  //         title: 'Company Profile',
  //         href: '/app/account',
  //         icon: BriefcaseIcon
  //       },
  //       {
  //         title: 'Operations Assessment',
  //         icon: ActivityIcon,
  //         href: '/app/assessment'
  //       },
  //       {
  //         title: 'Ops Dashboard',
  //         icon: PieChartIcon,
  //         href: '/app/report'
  //       }

  //       // {
  //       //   title: 'Risk Report',
  //       //   icon: ClipboardIcon,
  //       //   href: '/app/reports/dashboard'
  //       // },
  //       // {
  //       //   title: 'Task Calendar',
  //       //   href: '/app/calendar',
  //       //   icon: CalendarIcon
  //       // }
  //       // {
  //       //   title: 'Kanban',
  //       //   href: '/app/kanban',
  //       //   icon: TrelloIcon
  //       // },
  //     ]
  //   }
  //   // {
  //   //   subheader: 'Reports',
  //   //   items: [
  //   //     {
  //   //       title: 'Dashboard',
  //   //       icon: PieChartIcon,
  //   //       href: '/app/reports/dashboard'
  //   //     },
  //   //     {
  //   //       title: 'Dashboard Alternative',
  //   //       icon: BarChartIcon,
  //   //       href: '/app/reports/dashboard-alternative'
  //   //     }
  //   //   ]
  //   // },
  //   // {
  //   //   subheader: 'Management',
  //   //   items: [
  //   //     {
  //   //       title: 'Customers',
  //   //       icon: UsersIcon,
  //   //       href: '/app/management/customers',
  //   //       items: [
  //   //         {
  //   //           title: 'List Customers',
  //   //           href: '/app/management/customers'
  //   //         },
  //   //         {
  //   //           title: 'View Customer',
  //   //           href: '/app/management/customers/1'
  //   //         },
  //   //         {
  //   //           title: 'Edit Customer',
  //   //           href: '/app/management/customers/1/edit'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Products',
  //   //       icon: ShoppingCartIcon,
  //   //       href: '/app/management/products',
  //   //       items: [
  //   //         {
  //   //           title: 'List Products',
  //   //           href: '/app/management/products'
  //   //         },
  //   //         {
  //   //           title: 'Create Product',
  //   //           href: '/app/management/products/create'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Orders',
  //   //       icon: FolderIcon,
  //   //       href: '/app/management/orders',
  //   //       items: [
  //   //         {
  //   //           title: 'List Orders',
  //   //           href: '/app/management/orders'
  //   //         },
  //   //         {
  //   //           title: 'View Order',
  //   //           href: '/app/management/orders/1'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Invoices',
  //   //       icon: ReceiptIcon,
  //   //       href: '/app/management/invoices',
  //   //       items: [
  //   //         {
  //   //           title: 'List Invoices',
  //   //           href: '/app/management/invoices'
  //   //         },
  //   //         {
  //   //           title: 'View Invoice',
  //   //           href: '/app/management/invoices/1'
  //   //         }
  //   //       ]
  //   //     }
  //   //   ]
  //   // },
  //   // {
  //   //   subheader: 'Admin',
  //   //   items: [
  //   //     {
  //   //       title: 'Projects Platform',
  //   //       href: '/app/projects',
  //   //       icon: BriefcaseIcon,
  //   //       items: [
  //   //         {
  //   //           title: 'Overview',
  //   //           href: '/app/projects/overview'
  //   //         },
  //   //         {
  //   //           title: 'Browse Projects',
  //   //           href: '/app/projects/browse'
  //   //         },
  //   //         {
  //   //           title: 'Create Project',
  //   //           href: '/app/projects/create'
  //   //         },
  //   //         {
  //   //           title: 'View Project',
  //   //           href: '/app/projects/1'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Social Platform',
  //   //       href: '/app/social',
  //   //       icon: ShareIcon,
  //   //       items: [
  //   //         {
  //   //           title: 'Profile',
  //   //           href: '/app/social/profile'
  //   //         },
  //   //         {
  //   //           title: 'Feed',
  //   //           href: '/app/social/feed'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Kanban',
  //   //       href: '/app/kanban',
  //   //       icon: TrelloIcon
  //   //     },
  //   //     {
  //   //       title: 'Mail',
  //   //       href: '/app/mail',
  //   //       icon: MailIcon
  //   //     },
  //   //     {
  //   //       title: 'Chat',
  //   //       href: '/app/chat',
  //   //       icon: MessageCircleIcon,
  //   //       info: () => <Chip color="secondary" size="small" label="Updated" />
  //   //     },
  //   //     {
  //   //       title: 'Calendar',
  //   //       href: '/app/calendar',
  //   //       icon: CalendarIcon,
  //   //       info: () => <Chip color="secondary" size="small" label="Updated" />
  //   //     }
  //   //   ]
  //   // },
  //   // {
  //   //   subheader: 'Auth',
  //   //   items: [
  //   //     {
  //   //       title: 'Login',
  //   //       href: '/login-unprotected',
  //   //       icon: LockIcon
  //   //     },
  //   //     {
  //   //       title: 'Register',
  //   //       href: '/register-unprotected',
  //   //       icon: UserPlusIcon
  //   //     }
  //   //   ]
  //   // },
  //   // {
  //   //   subheader: 'Pages',
  //   //   items: [
  //   //     {
  //   //       title: 'Account',
  //   //       href: '/app/account',
  //   //       icon: UserIcon
  //   //     },
  //   //     {
  //   //       title: 'Error',
  //   //       href: '/404',
  //   //       icon: AlertCircleIcon
  //   //     },
  //   //     {
  //   //       title: 'Pricing',
  //   //       href: '/pricing',
  //   //       icon: DollarSignIcon
  //   //     }
  //   //   ]
  //   // },
  //   // {
  //   //   subheader: 'Extra',
  //   //   items: [
  //   //     {
  //   //       title: 'Charts',
  //   //       href: '/app/extra/charts',
  //   //       icon: BarChartIcon,
  //   //       items: [
  //   //         {
  //   //           title: 'Apex Charts',
  //   //           href: '/app/extra/charts/apex'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Forms',
  //   //       href: '/app/extra/forms',
  //   //       icon: EditIcon,
  //   //       items: [
  //   //         {
  //   //           title: 'Formik',
  //   //           href: '/app/extra/forms/formik'
  //   //         },
  //   //         {
  //   //           title: 'Redux Forms',
  //   //           href: '/app/extra/forms/redux'
  //   //         }
  //   //       ]
  //   //     },
  //   //     {
  //   //       title: 'Editors',
  //   //       href: '/app/extra/editors',
  //   //       icon: LayoutIcon,
  //   //       items: [
  //   //         {
  //   //           title: 'DraftJS Editor',
  //   //           href: '/app/extra/editors/draft-js'
  //   //         },
  //   //         {
  //   //           title: 'Quill Editor',
  //   //           href: '/app/extra/editors/quill'
  //   //         }
  //   //       ]
  //   //     }
  //   //   ]
  //   // }
  // ]);

  // // const sections = [
  // //   {
  // //     subheader: 'Founder Dashboard',
  // //     items: [
  // //       {
  // //         title: 'Instructions',
  // //         icon: ListIcon,
  // //         href: '/app/instructions'
  // //       },
  // //       {
  // //         title: 'Company Profile',
  // //         href: '/app/account',
  // //         icon: BriefcaseIcon
  // //       },
  // //       {
  // //         title: 'Operations Assessment',
  // //         icon: ActivityIcon,
  // //         href: '/app/assessment'
  // //       },
  // //       {
  // //         title: 'Ops Dashboard',
  // //         icon: PieChartIcon,
  // //         href: '/app/report'
  // //       }

  // //       // {
  // //       //   title: 'Risk Report',
  // //       //   icon: ClipboardIcon,
  // //       //   href: '/app/reports/dashboard'
  // //       // },
  // //       // {
  // //       //   title: 'Task Calendar',
  // //       //   href: '/app/calendar',
  // //       //   icon: CalendarIcon
  // //       // }
  // //       // {
  // //       //   title: 'Kanban',
  // //       //   href: '/app/kanban',
  // //       //   icon: TrelloIcon
  // //       // },
  // //     ]
  // //   }
  // //   // {
  // //   //   subheader: 'Reports',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Dashboard',
  // //   //       icon: PieChartIcon,
  // //   //       href: '/app/reports/dashboard'
  // //   //     },
  // //   //     {
  // //   //       title: 'Dashboard Alternative',
  // //   //       icon: BarChartIcon,
  // //   //       href: '/app/reports/dashboard-alternative'
  // //   //     }
  // //   //   ]
  // //   // },
  // //   // {
  // //   //   subheader: 'Management',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Customers',
  // //   //       icon: UsersIcon,
  // //   //       href: '/app/management/customers',
  // //   //       items: [
  // //   //         {
  // //   //           title: 'List Customers',
  // //   //           href: '/app/management/customers'
  // //   //         },
  // //   //         {
  // //   //           title: 'View Customer',
  // //   //           href: '/app/management/customers/1'
  // //   //         },
  // //   //         {
  // //   //           title: 'Edit Customer',
  // //   //           href: '/app/management/customers/1/edit'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Products',
  // //   //       icon: ShoppingCartIcon,
  // //   //       href: '/app/management/products',
  // //   //       items: [
  // //   //         {
  // //   //           title: 'List Products',
  // //   //           href: '/app/management/products'
  // //   //         },
  // //   //         {
  // //   //           title: 'Create Product',
  // //   //           href: '/app/management/products/create'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Orders',
  // //   //       icon: FolderIcon,
  // //   //       href: '/app/management/orders',
  // //   //       items: [
  // //   //         {
  // //   //           title: 'List Orders',
  // //   //           href: '/app/management/orders'
  // //   //         },
  // //   //         {
  // //   //           title: 'View Order',
  // //   //           href: '/app/management/orders/1'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Invoices',
  // //   //       icon: ReceiptIcon,
  // //   //       href: '/app/management/invoices',
  // //   //       items: [
  // //   //         {
  // //   //           title: 'List Invoices',
  // //   //           href: '/app/management/invoices'
  // //   //         },
  // //   //         {
  // //   //           title: 'View Invoice',
  // //   //           href: '/app/management/invoices/1'
  // //   //         }
  // //   //       ]
  // //   //     }
  // //   //   ]
  // //   // },
  // //   // {
  // //   //   subheader: 'Admin',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Projects Platform',
  // //   //       href: '/app/projects',
  // //   //       icon: BriefcaseIcon,
  // //   //       items: [
  // //   //         {
  // //   //           title: 'Overview',
  // //   //           href: '/app/projects/overview'
  // //   //         },
  // //   //         {
  // //   //           title: 'Browse Projects',
  // //   //           href: '/app/projects/browse'
  // //   //         },
  // //   //         {
  // //   //           title: 'Create Project',
  // //   //           href: '/app/projects/create'
  // //   //         },
  // //   //         {
  // //   //           title: 'View Project',
  // //   //           href: '/app/projects/1'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Social Platform',
  // //   //       href: '/app/social',
  // //   //       icon: ShareIcon,
  // //   //       items: [
  // //   //         {
  // //   //           title: 'Profile',
  // //   //           href: '/app/social/profile'
  // //   //         },
  // //   //         {
  // //   //           title: 'Feed',
  // //   //           href: '/app/social/feed'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Kanban',
  // //   //       href: '/app/kanban',
  // //   //       icon: TrelloIcon
  // //   //     },
  // //   //     {
  // //   //       title: 'Mail',
  // //   //       href: '/app/mail',
  // //   //       icon: MailIcon
  // //   //     },
  // //   //     {
  // //   //       title: 'Chat',
  // //   //       href: '/app/chat',
  // //   //       icon: MessageCircleIcon,
  // //   //       info: () => <Chip color="secondary" size="small" label="Updated" />
  // //   //     },
  // //   //     {
  // //   //       title: 'Calendar',
  // //   //       href: '/app/calendar',
  // //   //       icon: CalendarIcon,
  // //   //       info: () => <Chip color="secondary" size="small" label="Updated" />
  // //   //     }
  // //   //   ]
  // //   // },
  // //   // {
  // //   //   subheader: 'Auth',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Login',
  // //   //       href: '/login-unprotected',
  // //   //       icon: LockIcon
  // //   //     },
  // //   //     {
  // //   //       title: 'Register',
  // //   //       href: '/register-unprotected',
  // //   //       icon: UserPlusIcon
  // //   //     }
  // //   //   ]
  // //   // },
  // //   // {
  // //   //   subheader: 'Pages',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Account',
  // //   //       href: '/app/account',
  // //   //       icon: UserIcon
  // //   //     },
  // //   //     {
  // //   //       title: 'Error',
  // //   //       href: '/404',
  // //   //       icon: AlertCircleIcon
  // //   //     },
  // //   //     {
  // //   //       title: 'Pricing',
  // //   //       href: '/pricing',
  // //   //       icon: DollarSignIcon
  // //   //     }
  // //   //   ]
  // //   // },
  // //   // {
  // //   //   subheader: 'Extra',
  // //   //   items: [
  // //   //     {
  // //   //       title: 'Charts',
  // //   //       href: '/app/extra/charts',
  // //   //       icon: BarChartIcon,
  // //   //       items: [
  // //   //         {
  // //   //           title: 'Apex Charts',
  // //   //           href: '/app/extra/charts/apex'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Forms',
  // //   //       href: '/app/extra/forms',
  // //   //       icon: EditIcon,
  // //   //       items: [
  // //   //         {
  // //   //           title: 'Formik',
  // //   //           href: '/app/extra/forms/formik'
  // //   //         },
  // //   //         {
  // //   //           title: 'Redux Forms',
  // //   //           href: '/app/extra/forms/redux'
  // //   //         }
  // //   //       ]
  // //   //     },
  // //   //     {
  // //   //       title: 'Editors',
  // //   //       href: '/app/extra/editors',
  // //   //       icon: LayoutIcon,
  // //   //       items: [
  // //   //         {
  // //   //           title: 'DraftJS Editor',
  // //   //           href: '/app/extra/editors/draft-js'
  // //   //         },
  // //   //         {
  // //   //           title: 'Quill Editor',
  // //   //           href: '/app/extra/editors/quill'
  // //   //         }
  // //   //       ]
  // //   //     }
  // //   //   ]
  // //   // }
  // // ];

  // console.log(s);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
