import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import axios from 'src/utils/axios';
// import objFromArray from 'src/utils/objFromArray';
import firebase from 'src/lib/firebase';
// import useAuth from 'src/hooks/useAuth';
// import axios from 'axios';

import // collection,
// query,
// where,
// getDocs,
// setDocs,
// doc,
// addDoc,
// setDoc
  'firebase/firestore';
// var db = firebase.firestore();

firebase.appCheck().setTokenAutoRefreshEnabled(true);

const initialState = {
  isLoaded: false,
  // lists: {
  //   byId: {},
  //   allIds: []
  // },
  applications: {},
  profiles: {},
  targets: {},
  partnerInfo: {},
  partnerProfiles: {},
  partnerMembers: {},
  groups: {}
  // cards: {
  //   byId: {},
  //   allIds: []
  // },
  // members: {
  //   byId: {},
  //   allIds: []
  // }
};

const slice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    getPartner(state, action) {
      const partner = action.payload;

      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log(partner);
      // state.lists.byId = objFromArray(board.lists);
      // state.lists.allIds = Object.keys(state.lists.byId);
      // state.cards.byId = objFromArray(board.cards);
      // state.cards.allIds = Object.keys(state.cards.byId);
      // state.members.byId = objFromArray(board.members);
      // state.members.allIds = Object.keys(state.members.byId);
      state.applications = partner[0];
      state.targets = partner[1];
      state.profiles = partner[2];
      state.connections = partner[3];
      state.groups = partner[4];
      // state.questions = partner[5];
      state.isLoaded = true;
    },
    getGroups(state, action) {
      // const partner = action.payload;

      console.log('getGroups');
      console.log('getGroups');
      console.log('getGroups');
      console.log('getGroups');
      console.log('getGroups');
      // // console.log('profile get board');
      // // console.log('profile get board');
      // // console.log('profile get board');
      // // console.log('profile get board');
      // // console.log('profile get board');
      // // console.log(partner);
      // // state.lists.byId = objFromArray(board.lists);
      // // state.lists.allIds = Object.keys(state.lists.byId);
      // // state.cards.byId = objFromArray(board.cards);
      // // state.cards.allIds = Object.keys(state.cards.byId);
      // // state.members.byId = objFromArray(board.members);
      // // state.members.allIds = Object.keys(state.members.byId);
      // state.applications = partner[0];
      // state.targets = partner[1];
      // state.profiles = partner[2];
      // state.connections = partner[3];
      // state.isLoaded = true;
    },
    updateSelected(state, action) {
      const connection = action.payload;

      // console.log('connection');
      // console.log(connection);

      // var holder = state.connections;
      // console.log(holder);

      // let obj = connection.connections.find(o => o.id === connection.id);
      // console.log(obj);
      var index = connection.connections
        .map(function (e) {
          return e.name;
        })
        .indexOf(connection.id);
      // console.log('index');
      // console.log(index);

      var tempConnections = JSON.parse(JSON.stringify(connection.connections));

      // function myFunction(item, index, arr) {
      //   var tempConnectionHolder = JSON.parse(JSON.stringify(arr[index]));
      //   tempConnectionHolder.selected = item.status;
      //   tempConnections[index] = tempConnectionHolder;
      // }

      // index.forEach(myFunction);

      var tempConnectionHolder = JSON.parse(
        JSON.stringify(connection.connections[index])
      );
      tempConnectionHolder.selected = connection.status;
      tempConnections[index] = tempConnectionHolder;

      state.connections = tempConnections;
    },
    createGroup(state, action) {
      const group = action.payload;

      state.groups.push(group);
    },
    deleteGroup(state, action) {
      const group = action.payload;

      var passedGroups = JSON.parse(JSON.stringify(state.groups));
      passedGroups = passedGroups.filter(obj => {
        return obj.id != group.id;
      });

      state.groups = passedGroups;


      // state.groups.push(group);
    },
    updateSelectedGroup(state, action) {
      const group = action.payload;

      // console.log('connection');
      // console.log(connection);

      // var holder = state.connections;
      // console.log(holder);

      // let obj = connection.connections.find(o => o.id === connection.id);
      // console.log(obj);
      var index = group.groups
        .map(function (e) {
          return e.name;
        })
        .indexOf(group.id);
      // console.log('index');
      // console.log(index);

      var tempGroups = JSON.parse(JSON.stringify(group.groups));

      // function myFunction(item, index, arr) {
      //   var tempConnectionHolder = JSON.parse(JSON.stringify(arr[index]));
      //   tempConnectionHolder.selected = item.status;
      //   tempConnections[index] = tempConnectionHolder;
      // }

      // index.forEach(myFunction);

      var tempGroupHolder = JSON.parse(JSON.stringify(group.groups[index]));
      tempGroupHolder.selected = group.status;
      tempGroups[index] = tempGroupHolder;

      state.groups = tempGroups;
    },
    setPartner(state, action) {
      // const partner = action.payload;
      state.partner = action.payload;
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log(partner);
      // state.lists.byId = objFromArray(board.lists);
      // state.lists.allIds = Object.keys(state.lists.byId);
      // state.cards.byId = objFromArray(board.cards);
      // state.cards.allIds = Object.keys(state.cards.byId);
      // state.members.byId = objFromArray(board.members);
      // state.members.allIds = Object.keys(state.members.byId);
      // state.applications = partner[0];
      // state.targets = partner[1];
      // state.profiles = partner[2];
      // state.isLoaded = true;
    },
    setPartnerMembers(state, action) {
      // const partner = action.payload;
      state.partnerMembers = action.payload;
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log(partner);
      // state.lists.byId = objFromArray(board.lists);
      // state.lists.allIds = Object.keys(state.lists.byId);
      // state.cards.byId = objFromArray(board.cards);
      // state.cards.allIds = Object.keys(state.cards.byId);
      // state.members.byId = objFromArray(board.members);
      // state.members.allIds = Object.keys(state.members.byId);
      // state.applications = partner[0];
      // state.targets = partner[1];
      // state.profiles = partner[2];
      // state.isLoaded = true;
    },
    setPartnerInfo(state, action) {
      // const partnerInfo = action.payload;
      state.partnerInfo = action.payload;
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log('profile get board');
      // console.log(partner);
      // state.lists.byId = objFromArray(board.lists);
      // state.lists.allIds = Object.keys(state.lists.byId);
      // state.cards.byId = objFromArray(board.cards);
      // state.cards.allIds = Object.keys(state.cards.byId);
      // state.members.byId = objFromArray(board.members);
      // state.members.allIds = Object.keys(state.members.byId);
      // state.applications = partner[0];
      // state.targets = partner[1];
      // state.profiles = partner[2];
      // state.isLoaded = true;
    },
    createList(state, action) {
      const {list} = action.payload;

      state.lists.byId[list.id] = list;
      state.lists.allIds.push(list.id);
    },
    updateList(state, action) {
      const {list} = action.payload;

      state.lists.byId[list.id] = list;
    },
    clearList(state, action) {
      const {listId} = action.payload;
      const {cardIds} = state.lists.byId[listId];

      state.lists.byId[listId].cardIds = [];
      state.cards.byId = _.omit(state.cards.byId, cardIds);
      _.pull(state.cards.allIds, ...cardIds);
    },
    deleteList(state, action) {
      const {listId} = action.payload;

      state.lists.byId = _.omit(state.lists.byId, listId);
      _.pull(state.lists.allIds, listId);
    },
    createCard(state, action) {
      const {card} = action.payload;

      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);
      state.lists.byId[card.listId].cardIds.push(card.id);
    },
    updateCard(state, action) {
      const {card} = action.payload;

      _.merge(state.cards.byId[card.id], card);
    },
    moveCard(state, action) {
      const {cardId, position, listId} = action.payload;
      const {listId: sourceListId} = state.cards.byId[cardId];

      // Remove card from source list
      _.pull(state.lists.byId[sourceListId].cardIds, cardId);

      // If listId arg exists, it means that
      // we have to add the card to the new list
      if (listId) {
        state.cards.byId[cardId].listId = listId;
        state.lists.byId[listId].cardIds.splice(position, 0, cardId);
      } else {
        state.lists.byId[sourceListId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state, action) {
      const {cardId} = action.payload;
      const {listId} = state.cards.byId[cardId];

      state.cards.byId = _.omit(state.cards.byId, cardId);
      _.pull(state.cards.allIds, cardId);
      _.pull(state.lists.byId[listId].cardIds, cardId);
    },
    addComment(state, action) {
      const {comment} = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    addChecklist(state, action) {
      const {cardId, checklist} = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    updateChecklist(state, action) {
      const {cardId, checklist} = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, _checklist => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(state, action) {
      const {cardId, checklistId} = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.reject(card.checklists, {id: checklistId});
    },
    addCheckItem(state, action) {
      const {cardId, checklistId, checkItem} = action.payload;
      const card = state.cards.byId[cardId];

      _.assign(card, {
        checklists: _.map(card.checklists, checklist => {
          if (checklist.id === checklistId) {
            _.assign(checklist, {
              checkItems: [...checklist.checkItems, checkItem]
            });
          }

          return checklist;
        })
      });
    },
    updateCheckItem(state, action) {
      const {cardId, checklistId, checkItem} = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, checklist => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.map(checklist.checkItems, _checkItem => {
              if (_checkItem.id === checkItem.id) {
                return checkItem;
              }

              return _checkItem;
            })
          });
        }

        return checklist;
      });
    },
    deleteCheckItem(state, action) {
      const {cardId, checklistId, checkItemId} = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = _.map(card.checklists, checklist => {
        if (checklist.id === checklistId) {
          _.assign(checklist, {
            checkItems: _.reject(checklist.checkItems, {id: checkItemId})
          });
        }

        return checklist;
      });
    }
  }
});

export const reducer = slice.reducer;

export const getGroups = profile => async dispatch => {
  //if you are in more than ten Partners this will fail

  console.log('getGroups');
  console.log('getGroups');
  console.log('getGroups');
  console.log('getGroups');

  const targetsSnapshot = await firebase
    .firestore()
    .collection('groups')
    .where('partnerId', 'in', profile.partnerId)
    .get();

  var targetsHolder = [];

  var tempTargets = targetsSnapshot.docs.map(doc => {
    // console.log(doc.id);
    // var tempId = doc.id;
    targetsHolder[doc.id] = doc.data();
    return {id: doc.id, ...doc.data()};
  });

  dispatch(slice.actions.getGroups(tempTargets));
};

export const getPartner = email => async dispatch => {
  // console.log("slice-partner - getPartner - email");
  // console.log(email);

//  var holder = email.split('@');
//
//  holder = holder.pop();
  // const domain = parser(email).domain;

  // console.log(holder);

  const targetsSnapshot = await firebase
    .firestore()
    .collection('targets')
    // .where('user', '==', user.user.id)
    .get();

  var targetsHolder = [];

  var tempTargets = targetsSnapshot.docs.map(doc => {
    // console.log(doc.id);
    // var tempId = doc.id;
    targetsHolder[doc.id] = doc.data();
    return {id: doc.id, ...doc.data()};
  });

  const profilesSnapshot = await firebase
    .firestore()
    .collection('profiles')
    .where('email', '==', email)
    .get();

  var tempProfiles = profilesSnapshot.docs.map(doc => {
    //console.log(doc);
    return {id: doc.id, ...doc.data()};
  });

//  console.log("partnerId");
//  console.log("partnerId");
//  console.log("partnerId");
//  console.log("partnerId");
//  console.log("partnerId");
//  console.log("partnerId");
//  console.log(tempProfiles[0].partnerId);

  const groupsSnapshot = await firebase
    .firestore()
    .collection('groups')
    .where('partnerId', 'in', tempProfiles[0].partnerId)
    .get();

  var groupsHolder = [];

  var tempGroups = groupsSnapshot.docs.map(doc => {
    // console.log(doc.id);
    // var tempId = doc.id;
    groupsHolder[doc.id] = doc.data();
    return {id: doc.id, ...doc.data()};
  });

  // console.log("tempGroups[0]");
  // console.log(tempGroups[0]);

  var otherHolder = {
    id: 'OTHEROTHEROTHEROTHER',
    name: 'Other',
    partnerId: tempProfiles[0].partnerId,
    selected: true,
    profiles: []
  };

  tempGroups.push(otherHolder);

  // dispatch(slice.actions.getGroups(tempTargets));

  // console.log("tempGroups");
  // console.log(tempGroups);

  const partners = await firebase
    .firestore()
    .collection('partners')
    .where('partnerId', 'in', tempProfiles[0].partnerId)
    .get();
  var tempPartners = partners.docs.map(doc => {
    //console.log(doc);
    return doc.data().partnerId;
  });
  var tempPartners2 = partners.docs.map(doc => {
    //console.log(doc);
    return {id: doc.id, ...doc.data()};
  });
  var tempPartnersId = partners.docs.map(doc => {
    //console.log(doc);
    return doc.id;
  });

  // console.log("tempPartners");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log("tempPartners2");
  // console.log(tempPartners2);
  //   console.log("tempPartners");
  // console.log(tempPartners);

  dispatch(slice.actions.setPartner(tempPartners));
  dispatch(slice.actions.setPartnerInfo(tempPartners2));

  // var partnerId = tempPartners[0].id;

  const partnerMembers = await firebase
    .firestore()
    .collection('profiles')
    .where('partnerId', 'array-contains-any', tempPartners)
    // .doc(partnerId)
    .get();

  const tempPartnerMembers = partnerMembers.docs.map(doc => {
    return {id: doc.id, ...doc.data()};
  });

//  console.log("tempPartnerMembers");
//  console.log(tempPartnerMembers);
//
//
//  console.log("tempPartnersId");
//  console.log(tempPartnersId);

  dispatch(slice.actions.setPartnerMembers(tempPartnerMembers));

  const connections = await firebase
    .firestore()
    .collection('connections')
    .where('partner', 'in', tempPartnersId)
    .where('status', '==', 'connected')
    .get();
  var tempConnections = connections.docs.map(doc => {
    //console.log(doc);
    return {id: doc.id, selected: true, ...doc.data()};
  });
  // console.log("tempConnections");
  // console.log(tempConnections);

  var profileIdholder = [];
  for (let i in tempConnections) {
    profileIdholder.push(tempConnections[i].user);
  }

//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log("profileIdholder");
//  console.log(profileIdholder);

  if (profileIdholder.length !== 0) {
    // console.log(profileIdholder.length == 0);

//    console.log('about to fetch profiles');
    var tempProfiles = [];
//
//    const profiles = await firebase
//      .firestore()
//      .collection('profiles')
//      .where('user', 'in', profileIdholder)
//      .orderBy('updated_date', 'desc')
//      .get();
//    var tempProfiles = profiles.docs.map(doc => {
//      //console.log(doc);
//      return {id: doc.id, ...doc.data()};
//    });

    for(let i in profileIdholder){
      const portfolioprofile = await firebase
            .firestore()
            .collection('profiles')
            .where('user', '==', profileIdholder[i])
            .orderBy('updated_date', 'desc')
            .get();
      var tempPortfolioProfile = portfolioprofile.docs.map(doc => {
        //console.log(doc);
        return {id: doc.id, ...doc.data()};
      });
//      console.log("tempPortfolioProfile");
//      console.log(tempPortfolioProfile[0]);
      tempProfiles.push(tempPortfolioProfile[0]);
    }






    // console.log("tempProfiles");
    // console.log(tempProfiles);
    // setProfiles(tempProfiles);

//    var profileIds = [];
//    for (let i in tempProfiles) {
//      profileIds.push(tempProfiles[i].id);
//    }

    // console.log('profileIds');
    // console.log(profileIds);

//    console.log('about to fetch applications');

    var appIds = [];
    var tempApplications = [];



//    console.log('profileIdholder');
//    console.log(profileIdholder);

    for(let i in profileIdholder){

      const applications = await firebase
      .firestore()
      .collection('applications')
      .where('user', '==', profileIdholder[i])
      .orderBy('created_date', 'desc')
      .get();


      var tempApplicationsSingle = applications.docs.map(doc => {
        //console.log(doc);
        appIds.push(doc.id);
        return {id: doc.id, ...doc.data()};
      });
      tempApplications = tempApplications.concat(tempApplicationsSingle);




    }


//    let urlforfetch =
//      'https://osneycapital.com/api/v1/application/' + appIds[0];
    //
    // let resp = await axios.get('https://app.osneycapital.com/api/v1/application/' + appIds[0]);
    // console.log('urlforfetch');
    // console.log(urlforfetch);

//    var holder = [];


//    console.log('appIds');
//    console.log(appIds);

    for (let i in appIds) {
      let urlforfetch =
        'https://bridgeforstartups.com/api/v1/application/' + appIds[i];

      let resp = {};

      await fetch(urlforfetch)
        .then(response => response.json())
        .then(data => (resp = data));

//
//      console.log("tempApplications");
//      console.log("tempApplications");
//      console.log("tempApplications");
//      console.log("tempApplications");
//      console.log("tempApplications");
//      console.log(tempApplications);
//


      let index = tempApplications.map(el => el.id).indexOf(appIds[i]);
//      console.log("index");
//      console.log(index);
//
//       console.log("resp");
//       console.log(resp);
       if(index!== -1){
         tempApplications[index].actions = resp.application.actions;
       }
      // holder.push(resp);
    }
  }

  // const questions = await firebase
  //     .firestore()
  //     .collection('questions')
  //     // .where('user', 'in', profileIdholder)
  //     // .orderBy('updated_date', 'desc')
  //     .get();
  // var tempQuestions = questions.docs.map(doc => {
  //   //console.log(doc);
  //   return { id: doc.id, ...doc.data() };
  // });

  tempProfiles = tempProfiles.filter(element => {
    return element !== undefined;
  });


  var holder = [
    tempApplications,
    targetsHolder,
    tempProfiles,
    tempConnections,
    tempGroups,
    // tempQuestions
  ];

  dispatch(slice.actions.getPartner(holder));

  // const email = user.user.email;
  // / const response = await axios.get('/api/kanban/board');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log('igetProfile');
  // // console.log(id);
  // var profileRef = db.collection('profiles').doc(id);
  // // var docRef = db.collection("cities").doc(id);
  // profileRef
  //   .get()
  //   .then(doc => {
  //     if (doc.exists) {
  //       // console.log("Document data:");
  //       // console.log("Document data:");
  //       // console.log("Document data:");
  //       // console.log("Document data:");
  //       // console.log("Document data:");
  //       //   console.log("Document data:", doc.data());
  //       dispatch(slice.actions.getProfile(doc.data()));
  //     } else {
  //       // doc.data() will be undefined in this case
  //       // console.log("No such document!");
  //       // console.log("No such document!");
  //       // console.log("No such document!");
  //       // console.log("No such document!");
  //       // console.log("No such document!");
  //       // console.log("No such document!");
  //     }
  //   })
  //   .catch(error => {
  //     console.log('Error getting document:', error);
  //   });
  // console.log('aboout to fire get board slide');
};

export const updateSelectedGroup = (groups, id, status) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {
  //   name
  // });
  // console.log("id");
  // console.log(id);
  // console.log("status");
  // console.log(status);

  var holder = {id: id, status: status, groups: groups};
  // console.log(holder);

  dispatch(slice.actions.updateSelectedGroup(holder));
};

export const addGroupConnection = (profileId, groupId) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {
  //   name
  // });
  console.log('addGroupConnection');
  console.log('profileIdd');
  console.log(profileId);
  console.log('groupId');
  console.log(groupId);

  var washingtonRef = firebase
    .firestore()
    .collection('groups')
    .doc(groupId);

  // Atomically add a new region to the "regions" array field.
  var arrUnion = washingtonRef.update({
    profiles: firebase.firestore.FieldValue.arrayUnion(profileId)
  });

  console.log('arrUnion');
  console.log(arrUnion);

  // dispatch(slice.actions.updateSelectedGroup(holder));
};

export const deleteGroupConnection = (profileId, groupId) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {
  //   name
  // });
  console.log('addGroupConnection');
  console.log('profileIdd');
  console.log(profileId);
  console.log('groupId');
  console.log(groupId);

  var washingtonRef = firebase
    .firestore()
    .collection('groups')
    .doc(groupId);

  // Atomically add a new region to the "regions" array field.
  var arrUnion = washingtonRef.update({
    profiles: firebase.firestore.FieldValue.arrayRemove(profileId)
  });

  // console.log("arrUnion");
  // console.log(arrUnion);

  // dispatch(slice.actions.updateSelectedGroup(holder));
};

export const createGroup = (groupName, partnerTemp) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {

  // var holder = {
  //   id: 'NEWD',
  //   name: groupName,
  //   selected: true,
  //   partnerId: partnerTemp.partner[0],
  //   profiles: ['FrGmhDvgg4ZKEnzT1BRGifPUevj1', 'ILzQHXDnNDPLwNTzwFpfOUHluce2']
  // };

  var holder = {
    name: groupName,
    selected: true,
    partnerId: partnerTemp.partner[0],
    profiles: []
  };

  firebase
    .firestore()
    .collection('groups')
    .add(holder)
    .then(docRef => {
      // console.log('Document written with ID: ', docRef.id);
      holder['id'] = docRef.id;
      dispatch(slice.actions.createGroup(holder));
    });


};

export const deleteGroup = (group, partnerTemp) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {


  firebase.firestore().collection("groups").doc(group.id).delete().then(() => {
    // console.log("Document successfully deleted!");
    dispatch(slice.actions.deleteGroup(group));


  }).catch((error) => {
    console.error("Error removing document: ", error);
  });


  // var holder = {
  //   id: 'NEWD',
  //   name: groupName,
  //   selected: true,
  //   partnerId: partnerTemp.partner[0],
  //   profiles: ['FrGmhDvgg4ZKEnzT1BRGifPUevj1', 'ILzQHXDnNDPLwNTzwFpfOUHluce2']
  // };

  // var holder = {
  //   name: groupName,
  //   selected: true,
  //   partnerId: partnerTemp.partner[0],
  //   profiles: []
  // };

  // firebase
  //   .firestore()
  //   .collection('groups')
  //   .add(holder)
  //   .then(docRef => {
  //     // console.log('Document written with ID: ', docRef.id);
  //     holder['id'] = docRef.id;
  //     dispatch(slice.actions.createGroup(holder));
  //   });


};


export const updateSelected = (connections, id, status) => async dispatch => {
  // const response = await axios.post('/api/kanban/lists/new', {
  //   name
  // });
  // console.log("id");
  // console.log(id);
  // console.log("status");
  // console.log(status);

  var holder = {id: id, status: status, connections: connections};
  dispatch(slice.actions.updateSelected(holder));
};

export const createList = name => async dispatch => {
  const response = await axios.post('/api/kanban/lists/new', {
    name
  });

  dispatch(slice.actions.createList(response.data));
};

export const updateList = (listId, update) => async dispatch => {
  const response = await axios.post('/api/kanban/list/update', {
    listId,
    update
  });

  dispatch(slice.actions.updateList(response.data));
};

export const clearList = listId => async dispatch => {
  await axios.post('/api/kanban/lists/clear', {
    listId
  });

  dispatch(slice.actions.clearList({listId}));
};

export const deleteList = listId => async dispatch => {
  await axios.post('/api/kanban/lists/remove', {
    listId
  });

  dispatch(slice.actions.deleteList({listId}));
};

export const createCard = (listId, name) => async dispatch => {
  const response = await axios.post('/api/kanban/cards/new', {
    listId,
    name
  });

  dispatch(slice.actions.createCard(response.data));
};

export const updateCard = (cardId, update) => async dispatch => {
  const response = await axios.post('/api/kanban/cards/update', {
    cardId,
    update
  });

  dispatch(slice.actions.updateCard(response.data));
};

export const moveCard = (cardId, position, listId) => async dispatch => {
  await axios.post('/api/kanban/cards/move', {
    cardId,
    position,
    listId
  });

  dispatch(
    slice.actions.moveCard({
      cardId,
      position,
      listId
    })
  );
};

export const deleteCard = cardId => async dispatch => {
  await axios.post('/api/kanban/cards/remove', {
    cardId
  });

  dispatch(slice.actions.deleteCard({cardId}));
};

export const addComment = (cardId, message) => async dispatch => {
  const response = await axios.post('/api/kanban/comments/new', {
    cardId,
    message
  });

  dispatch(slice.actions.addComment(response.data));
};

export const addChecklist = (cardId, name) => async dispatch => {
  const response = await axios.post('/api/kanban/checklists/new', {
    cardId,
    name
  });
  const {checklist} = response.data;

  dispatch(
    slice.actions.addChecklist({
      cardId,
      checklist
    })
  );
};

export const updateChecklist = (
  cardId,
  checklistId,
  update
) => async dispatch => {
  const response = await axios.post('/api/kanban/checklists/update', {
    cardId,
    checklistId,
    update
  });
  const {checklist} = response.data;

  dispatch(
    slice.actions.updateChecklist({
      cardId,
      checklist
    })
  );
};

export const deleteChecklist = (cardId, checklistId) => async dispatch => {
  await axios.post('/api/kanban/checklists/remove', {
    cardId,
    checklistId
  });

  dispatch(
    slice.actions.deleteChecklist({
      cardId,
      checklistId
    })
  );
};

export const addCheckItem = (cardId, checklistId, name) => async dispatch => {
  const response = await axios.post('/api/kanban/checkitems/new', {
    cardId,
    checklistId,
    name
  });
  const {checkItem} = response.data;

  dispatch(
    slice.actions.addCheckItem({
      cardId,
      checklistId,
      checkItem
    })
  );
};

export const updateCheckItem = (
  cardId,
  checklistId,
  checkItemId,
  update
) => async dispatch => {
  const response = await axios.post('/api/kanban/checkitems/update', {
    cardId,
    checklistId,
    checkItemId,
    update
  });
  const {checkItem} = response.data;

  dispatch(
    slice.actions.updateCheckItem({
      cardId,
      checklistId,
      checkItem
    })
  );
};

export const deleteCheckItem = (
  cardId,
  checklistId,
  checkItemId
) => async dispatch => {
  await axios.post('/api/kanban/checkitems/remove', {
    cardId,
    checklistId,
    checkItemId
  });

  dispatch(
    slice.actions.deleteCheckItem({
      cardId,
      checklistId,
      checkItemId
    })
  );
};

export default slice;
