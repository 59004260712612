import React, { useRef, useState, memo } from 'react';
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  makeStyles
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import GetAppIcon from '@material-ui/icons/GetApp';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(() => ({
  menu: {
    width: 256,
    maxWidth: '100%'
  },
  button: {
    borderWidth: '2px',
    borderColor: '#e3a477',
    borderStyle: 'solid',
    color: '#e3a477',
    background: 'transparent',
    transition: '0.1s background-color linear, 0.1s color linear',
    borderRadius: '300px',
    padding: '1.4em 2.338em',
    // fontFamily: 'kepler-std',
    // fontWeight: 'bolder',
    // fontStyle: 'normal',
    // letterSpacing: '.02em',
    textTransform: 'none',
    '&:hover': {
      background: '#e3a477',
      color: 'white'
    }
  }
}));

const OrangeHollowButton = ({ copy, ...rest }) => {
  const classes = useStyles();

  return (
    <Button
      className={classes.button}
      // color="secondary"
      // disabled={!submittable}
      type="submit"
      variant="contained"
      size="large"
      // fullWidth
      // style={{
      //   height: '60px',
      //   width: '150px'
      // }}
      gutterTop
      align="center"
    >
      <Typography
        variant="h4"
        // style={{ color: 'white' }}
        // color="textSecondary"
        align="center"
      >
        {copy}
      </Typography>
    </Button>
  );
};

export default memo(OrangeHollowButton);
