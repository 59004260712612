import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as profileReducer } from 'src/slices/profile';
import { reducer as applicationReducer } from 'src/slices/application';
import { reducer as targetsReducer } from 'src/slices/targets';
import { reducer as actionsReducer } from 'src/slices/actions';
import { reducer as partnerReducer } from 'src/slices/partnerMultiple';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  profile: profileReducer,
  application: applicationReducer,
  targets: targetsReducer,
  actions: actionsReducer,
  partner: partnerReducer,
  // partnerInfo: partnerReducer
});

export default rootReducer;
