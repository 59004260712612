/* eslint-disable no-use-before-define */
import React, {
  // useState
  // useEffect,
  // useCallback
} from 'react';
import {
  // useLocation, 
  matchPath
} from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  // Avatar,
  Box,
  // Chip,
  // Divider,
  Drawer,
  Hidden,
  Link,
  List,
  // ListSubheader,
  // Button,
  Typography,
  makeStyles
} from '@material-ui/core';
// import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
// import useIsMountedRef from 'src/hooks/useIsMountedRef';
// import { APP_VERSION } from 'src/constants';
import {
  Twitter as TwitterIcon,
  // Calendar as CalendarIcon,
  Linkedin as LinkedInIcon
} from 'react-feather';

// import {
// Briefcase as BriefcaseIcon,
// BookOpen as Book,
// Calendar as CalendarIcon,
// ShoppingCart as ShoppingCartIcon,
// Folder as FolderIcon,
// Twitter as TwitterIcon,
// Linkedin as LinkedInIcon,
// List as ListIcon,
// UserPlus as UserPlusIcon,
// AlertCircle as AlertCircleIcon,
// Trello as TrelloIcon,
// User as UserIcon,
// Layout as LayoutIcon,
// Edit as EditIcon,
// DollarSign as DollarSignIcon,
// Mail as MailIcon,
// MessageCircle as MessageCircleIcon,
// PieChart as PieChartIcon,
// Share2 as ShareIcon,
// Users as UsersIcon,
// Activity as ActivityIcon,
// HelpCircle as HelpCircleIcon,
// Globe as GlobeIcon,
// Smile as SmileIcon,
// Package as PackageIcon,
// Link as LinkIcon
// Clipboard as ClipboardIcon,
// HelpCircle as HelpCircleIcon
// } from 'react-feather';
// import Logo from 'src/components/Logo';
// import useAuth from 'src/hooks/useAuth';

import NavItem from './NavItem';

// import firebase from 'src/lib/firebase';
// import {
//   collection,
//   query,
//   where,
//   getDocs,
//   setDocs,
//   doc,
//   addDoc,
//   setDoc
// } from 'firebase/firestore';

// import firebase from 'src/lib/firebase';

function renderNavItems({items, pathname, depth = 0}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({acc, item, pathname, depth}),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({acc, pathname, item, depth}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: '60%',
    // backgroundColor:''
    // height: '100px',
    fontFamily: 'Baskerville'
  },
  desktopDrawer: {
    width: 256,
    top: 100,
    height: 'calc(100% - 100px)',
    fontFamily: 'Baskerville'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    fontFamily: 'Baskerville'
  },
  link: {
    color: 'white',
    paddingRight: '12px',
    padding: '10px 8px',
    fontFamily: 'Baskerville'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    fontFamily: 'Baskerville'
  }
}));

const NavBar = ({onMobileClose, openMobile}) => {
  const classes = useStyles();
  // const location = useLocation();
  // const { user } = useAuth();
  // user.status = user;
  // const isMountedRef = useIsMountedRef();

  // const [profile, setProfile] = useState();

  // let sections = [
  //   {
  //     // subheader: 'Founder Dashboard',
  //     items: [
  //       {
  //         title: 'Philosophy',
  //         icon: ListIcon,
  //         href: '/philosophy'
  //       },
  //       {
  //         title: 'Team',
  //         href: '/team',
  //         icon: BriefcaseIcon
  //       }
  //       // {
  //       //   title: 'Blog',
  //       //   icon: ActivityIcon,
  //       //   href: 'https://blog.osneycapital.com'
  //       // },
  //       // {
  //       //   title: 'LinkedIn',
  //       //   icon: LinkedInIcon,
  //       //   href: 'https://www.linkedin.com/company/osneycapital/'
  //       // },
  //       // {
  //       //   title: 'Twitter',
  //       //   icon: TwitterIcon,
  //       //   href: '/app/share'
  //       // }
  //     ]
  //   }
  // ];

  // var adminSections = {
  //   subheader: 'Admin',
  //   items: [
  //     {
  //       title: 'Overview',
  //       href: '/admin',
  //       icon: GlobeIcon
  //     },
  //     // {
  //     //   title: 'New Home',
  //     //   href: '/app/accountnew',
  //     //   icon: GlobeIcon
  //     // },
  //     {
  //       title: 'Profiles',
  //       href: '/admin/profiles',
  //       icon: SmileIcon
  //     },
  //     {
  //       title: 'Applications',
  //       href: '/admin/applications',
  //       icon: PackageIcon
  //     },
  //     {
  //       title: 'Partners',
  //       icon: ShareIcon,
  //       href: '/admin/partners'
  //     },
  //     {
  //       title: 'Questions',
  //       icon: HelpCircleIcon,
  //       href: '/admin/questions'
  //     },
  //     {
  //       title: 'Links',
  //       href: '/admin/links',
  //       icon: LinkIcon
  //     }
  //     // {
  //     //   title: 'Testing New - Ops Dashboard',
  //     //   icon: PieChartIcon,
  //     //   href: '/app/reportnew'
  //     // },
  //   ]
  // };

  // var partner = {
  //   subheader: 'Partner',
  //   items: [
  //     {
  //       title: 'Overview',
  //       href: '/partner',
  //       icon: GlobeIcon
  //     }
  //     // {
  //     //   title: 'Questions',
  //     //   icon: HelpCircleIcon,
  //     //   href: '/admin/questions'
  //     // },
  //     // {
  //     //   title: 'Profiles',
  //     //   href: '/admin/profiles',
  //     //   icon: SmileIcon
  //     // },
  //     // {
  //     //   title: 'Applications',
  //     //   href: '/admin/applications',
  //     //   icon: PackageIcon
  //     // },
  //     // {
  //     //   title: 'Links',
  //     //   href: '/admin/links',
  //     //   icon: LinkIcon
  //     // }
  //   ]
  // };

  // if (!!user.customClaims.partner) {
  //   // console.log('is channelPartner');
  //   sections.push(partner);
  //   // console.log(sections);
  // }

  // if (!!user.customClaims.admin) {
  //   // console.log('is admin');
  //   sections.push(adminSections);
  //   // console.log(sections);
  // }

  // console.log(user);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      style={{backgroundColor: '#002147'}}
    >
      <PerfectScrollbar options={{suppressScrollX: true}}>
        {/*<Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo style={{ maxHeight: '50px', maxWidth: '100%' }} />
            </RouterLink>
          </Box>
        </Hidden>*/}
        {/*<Box p={2}>*/}
        {/*<Box display="flex" justifyContent="center">*/}
        {/*<RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>*/}
        {/*</Box>*/}
        {/*<Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            {/*<Typography variant="body2" color="textSecondary">
              Your status:{' '}
              <Link component={RouterLink} to="/pricing">
                {user.status}
              </Link>
            </Typography>*/}
        {/*</Box>*/}
        {/*</Box>*/}
        {/*        <Divider />
         */}{' '}
        {/*<Box p={2} color="white">*/}
        {/*  <Typography align="center">*/}
        {/*    <Link*/}
        {/*      className={classes.link}*/}
        {/*      // color="white"*/}
        {/*      component={RouterLink}*/}
        {/*      to="/philosophy"*/}
        {/*      onClick={onMobileClose}*/}
        {/*      //onClick={onMobileClose()}*/}
        {/*      underline="none"*/}
        {/*      variant="body2"*/}
        {/*      style={{fontSize: '35px'}}*/}

        {/*      // style={{ marginRight: '10' }}*/}
        {/*    >*/}
        {/*      Philosophy*/}
        {/*    </Link>*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        {/*<Box p={2} color="white">*/}
        {/*  <Typography align="center">*/}
        {/*    <Link*/}
        {/*      className={classes.link}*/}
        {/*      // color="white"*/}
        {/*      component={RouterLink}*/}
        {/*      to="/team"*/}
        {/*      onClick={onMobileClose}*/}

        {/*      underline="none"*/}
        {/*      variant="body2"*/}
        {/*      style={{fontSize: '35px'}}*/}

        {/*      // style={{ marginRight: '10' }}*/}
        {/*    >*/}
        {/*      Team*/}
        {/*    </Link>*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        <Box p={2} color="white">
          <Typography align="center">
            <Link
              className={classes.link}
              // color="white"
              component={RouterLink}
              to="/contact"
              underline="none"
              onClick={onMobileClose}

              variant="body2"
              style={{fontSize: '35px'}}

              // style={{ marginRight: '10' }}
            >
              Contact
            </Link>
          </Typography>
        </Box>
        <Box p={2} color="white">
          {/*{sections.map(section => (
            <List
              key={section.subheader}
              style={{ fontFamily: 'Baskerville !important' }}
              subheader={
                <ListSubheader
                  disableGutters
                  disableSticky
                  style={{ fontFamily: 'Baskerville !important' }}
                >
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}*/}
          <Typography align="center">
            <Link
              className={classes.link}
              // color="white"
              component={RouterLink}
              // to="https://blog.osneycapital.com/"
              onClick={() => {
                console.log('click is clicking');
                onMobileClose();
                window.location.href = 'https://blog.osneycapital.com/';
              }}
              underline="none"
              variant="body2"
              style={{fontSize: '35px'}}
              // style={{ marginRight: '10' }}
            >
              {/*            <Book size="20" style={{marginTop:'10px',marginRight:'10px'}} />
               */}{' '}
              Blog
            </Link>
          </Typography>
        </Box>
        <Box p={2} color="white">
          <Typography align="center">
            <Link
              className={classes.link}
              color="textSecondary"
              component={RouterLink}
              // onClick={handleClick}
              onClick={() => {
                console.log('click is clicking');
                onMobileClose();
                window.open(
                  'https://www.linkedin.com/company/osneycapital/',
                  '_blank'
                );
                // window.location.href = 'https://blog.osneycapital.com/';
              }}
              underline="none"
              variant="body2"
            >
              <LinkedInIcon color="white" style={{marginRight: '10'}}/>
            </Link>
            <Link
              className={classes.link}
              color="textSecondary"
              component={RouterLink}
              // onClick={handleTwitterClick}
              onClick={() => {
                console.log('click is clicking');
                onMobileClose();
                window.open('https://twitter.com/osneycapital', '_blank');

              }}
              underline="none"
              variant="body2"
            >
              {/*<Divider  />*/}
              <TwitterIcon
                color="white"
                // style={{ marginRight: '10' }}
                // className={classes.divider}
              />
            </Link>
          </Typography>
        </Box>
        {/*<Box p={2} color="white">
          <Link
            // className={classes.link}
            color="textSecondary"
            component={RouterLink}
            to="/app/account"
            underline="none"
            variant="body2"
          >
            <Button
              color="white"
              component="a"
              // href="/app/account"
              variant="contained"
              size="large"
              fullWidth='true'
              // className={classes.padding}
            >
              Get Started
            </Button>
          </Link>
        </Box>*/}
        {/*<Box p={2}>
          {adminSections.map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>*/}
        {/*<Divider />*/}
        {/*<Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>*/}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="right"
          classes={{paper: classes.mobileDrawer}}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box
            display="flex"
            justifyContent="center"
            style={{
              minHeight: '65px',
              backgroundColor: '#002147',
              width: '100%'
            }}
          >
            <RouterLink to="/">
              {/*<Logo
                style={{
                  maxHeight: '50px',
                  maxWidth: '100%',
                  margin: '5px',
                  padding: '5px',
                  verticalAlign: 'middle'
                }}
              />*/}
            </RouterLink>
          </Box>
          {content}
        </Drawer>
      </Hidden>{' '}

    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
