import moment from 'moment';
import mock from 'src/utils/mock';

// console.log("Questions is firing");

let questions = [
  {
    "Record ID": "recGFysfg0f3CwjTQ",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Product - LTV",
    "Description": "",
    "Question": "To what extent is customer lifetime value tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recKnJoTplHEQGl3t",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Management bios",
    "Description": "Biographies of management",
    "Question": "Do you have a biography with work experience for all management?",
    "Documents Required": "Biographies of all management",
    "Answer 1 (Full Marks 5pts)": "We have a biography of all management",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "We are developing complete biographies for all management.",
    "Answer 4 (No Marks 0pts)": "We do not have a biography for all management",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recL6SwLQ4Vub9duC",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Employee turnover",
    "Description": "Employee turnover, absentee history",
    "Question": "Have there been employee leavers since the company was created?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "One person",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "More than one person",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recOALvEG7wgLeERf",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Financial - burn rate",
    "Description": "",
    "Question": "To what extent is cash burn rate tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recPZyuV6GrIUGNmy",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Competitors",
    "Description": "List and description of competitors, including strengths, weaknesses, market position, and basis of competition.",
    "Question": "Do you have a report on your marketing position in relation to your competitors?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have a in depth analysis of  relative strengths and weaknesses versus competitors.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Have some materials related to market positioning",
    "Answer 4 (No Marks 0pts)": "Have no formal document about market positioning",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgiPxlRzzOdBKdP",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Financial - profitability",
    "Description": "",
    "Question": "To what extent is profitability tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recpg6btCbi0nZhMe",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Financial - MRR",
    "Description": "",
    "Question": "To what extent is monthly recurring revenue tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recto5IjAB47e4x7B",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Strategy",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Value Creation",
    "Description": "How is value created in the business?",
    "Question": "Is there a clear articulation of how the business creates value?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, formally documented and understood",
    "Answer 2 (Full Marks 3pts)": "Articulated, but not documented",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recxQt9dwBluMJEuw",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Cap table",
    "Description": "",
    "Question": "Is your cap table clear, accurate and up to date?",
    "Documents Required": "share register; other records granting rights",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "out of date",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recBcPqsKzrX21pfj",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Org structure",
    "Description": "Employee reporting structure",
    "Question": "Is there an organisational tree that shows reporting structure for your organisation",
    "Documents Required": "Reporting structure",
    "Answer 1 (Full Marks 5pts)": "Yes, there is a defined and adhered to guide",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "There is a guide but it is subject to alteration on an ad-hoc basis",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recFtoMEtwZFKRxUd",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Sales MI - Proposal Conversion Rate",
    "Description": "",
    "Question": "To what extent is proposal conversion tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Tracked and reported at least monthly",
    "Answer 2 (Full Marks 3pts)": "Tracked and reported at least quarterly",
    "Answer 3 (Full Marks 1pts)": "Tracked and reported at least annually",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recQNZgFjyBx2uJW8",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs",
    "Description": "",
    "Question": "are  KPIs (churn, NPS, LTV & COCA) reported weekly to executive team",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "churn, NPS, LTV and COCA known and tracked",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recQsEMRv4xa6GZJp",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Directors and officers",
    "Description": "List of directors and officers",
    "Question": "Do you have a list of all directors and officers?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recTNglkH4fi43uH7",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Product",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Product use examples",
    "Description": "A list of major customers and product applications.",
    "Question": "Do you have a list of your major customers and details on how they use your product?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWLstePkzfE16Yv",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Product - COCA",
    "Description": "",
    "Question": "To what extent is cost of customer acquisition tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWf5cdVcjKSDD42",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Key Customers",
    "Description": "",
    "Question": "To what extent is the revenue of any customer >10% of company revenue contracted?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No termination for convenience",
    "Answer 2 (Full Marks 3pts)": "Termination for convenience >6m notice",
    "Answer 3 (Full Marks 1pts)": "Termination for convenience >3m notice",
    "Answer 4 (No Marks 0pts)": "Termination for convenience (no notice)",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recZcjwcOjIcsSMrX",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Sales MI - Opportunity Pipeline",
    "Description": "",
    "Question": "To what extent is opportunity pipeline tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded in CRM, with weekly pipeline reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded in CRM with monthly pipeline reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded offline, with monthly pipeline reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec9ERlC5EJmeIxrr",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Product - product utilsation",
    "Description": "",
    "Question": "To what extent is product utilisation (eg active users) tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reccjeJk2qQNaIJt3",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Founder turnover",
    "Description": "Employee turnover, absentee history",
    "Question": "Have there been founder leavers since the company was created?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Yes",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recdcN1DB5JyCgUU8",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "Asset Risk",
    "Theme Group": "Intellectual Property",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "IP rights",
    "Description": "",
    "Question": "Does the company own or have the right to use all key intellectual property?",
    "Documents Required": "IP advice / documentation",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "part owned/limited licence",
    "Answer 3 (Full Marks 1pts)": "transfers in process",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "receOzw0hRf9VKbKo",
    "Vanilla VC Question Stage": "Vanilla",
    "Owner": "Paul",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "KPIs - Product - churn",
    "Description": "",
    "Question": "To what extent is customer churn tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded with weekly  reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded with quarterly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recGoO2qBK5hsVeWs",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "reporting data breaches",
    "Description": "Does a process exist, and does the company know its obligations?",
    "Question": "Does a process exist for reporting data breaches, and does the company know its obligations?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Yes, documented process and procedure where each member of the team knows their role",
    "Answer 2 (Full Marks 3pts)": "Documented process and procedure",
    "Answer 3 (Full Marks 1pts)": "Process exists",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recGXvonAGUaG7DNX",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "5 - RE",
    "Stage Applicable": "Seed",
    "Item": "Post Incident System Recovery",
    "Description": "Recovering systems after an incident",
    "Question": "Does a process exist for recovering and restoring systems and data following an incident?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Processes in place to reover data and systems post-incident, mapped to business priorities, tested annually and updated based on performance",
    "Answer 2 (Full Marks 3pts)": "Process in place to recover data and systems mapped to business priorities",
    "Answer 3 (Full Marks 1pts)": "Process in place to recover data and systems",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recHP6tiYd6BdJ86k",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Employee loans, advances",
    "Description": "Employee loans, advances to company",
    "Question": "Have any founders or staff made an advance or a loan to the company",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes on defined payment schedule that is subordinate to equity note.",
    "Answer 4 (No Marks 0pts)": "Yes informally",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recHmvZyFhbAHtQQH",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Seed",
    "Item": "Data protection policy",
    "Description": "Data protection policy",
    "Question": "Does the company have a data protection policy in place?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recHt0QOeMT7oA7gj",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Network Segregation",
    "Description": "publically accessible and internal networks segregated and prod/dev environments",
    "Question": "Are the publicly accessible and internal networks segregated?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recIwLBnV0ihZfy2X",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Remote Access Attempts",
    "Description": "Remote access attempt monitoring",
    "Question": "Are remote access attempts recorded with the ability to alert for potential malicious activity?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, monitored continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, monitored daily",
    "Answer 3 (Full Marks 1pts)": "Yes, monitored weekly",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recIAzyY2iewXhWP4",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "insurance terms",
    "Description": "",
    "Question": "Have you identified and obtained coverage for your key risks?",
    "Documents Required": "policy review",
    "Answer 1 (Full Marks 5pts)": "yes, through a broker",
    "Answer 2 (Full Marks 3pts)": "yes, self-sourced",
    "Answer 3 (Full Marks 1pts)": "some policies in place",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 1,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recIiJFaPYWGLRv5V",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Customer / product Complaints",
    "Description": "",
    "Question": "is there monthly reporting of customer / product complaints to exec team and directors",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recJzcXRQCK9gp4vK",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Advertising",
    "Description": "Current ad programs, marketing budgets, and printed marketing materials.",
    "Question": "Do you have a marketing plan ?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, including content calendar for more than next month.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recKGCT4qILrSQ4ts",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Third Party Dependency",
    "Description": "Does the business know who it's third party providers are and the services provided?",
    "Question": "Does an inventory of third party service providers exist?",
    "Documents Required": "Inventory",
    "Answer 1 (Full Marks 5pts)": "Yes, and updated continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, updated periodically",
    "Answer 3 (Full Marks 1pts)": "Yes, updated infrequently",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recKX70RiI9eJtrPU",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Regulatory compliance",
    "Description": "relevant obligations understood and managed",
    "Question": "Mapping of business activity to regulatory requirements",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Fully mapped picture of regulatory obligations in all jurisdictions where the company operates",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "View of regulatory obligations in core markets",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recKkkhXSGxJsKV8I",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Order Processing System",
    "Description": "",
    "Question": "Order processing system being used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Order processing software is used by all relevant staff, with all orders tracked throughout the lifecycle",
    "Answer 2 (Full Marks 3pts)": "Order processing software implemented",
    "Answer 3 (Full Marks 1pts)": "Orders are tracked and fulfilled using spreadsheets (or similar)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recLPpiDTVHNLwoDT",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "Anti-malware/Anti-Virus",
    "Description": "regularly updated AMW/AV on endpoints",
    "Question": "Do computers (and any servers) have anti-malware and anti-virus software installed?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, multi-vendor, defence-in-depth strategy",
    "Answer 2 (Full Marks 3pts)": "Yes, some multi-vendor approaches",
    "Answer 3 (Full Marks 1pts)": "Single Vendor",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recL6ySndnk8PYeSc",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Tollerance for Deviation",
    "Description": "Established and agreed tollerance for key metrics",
    "Question": "Is there a defined and agreed tolerance for each of the key metrics being measured, and resulting actions if outside of tolerance?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Documented and agreed tollerances for each metric, updated regularly",
    "Answer 2 (Full Marks 3pts)": "Documented and agreed tollerances for each metric",
    "Answer 3 (Full Marks 1pts)": "Tollerances for each metric informally known",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recL7iGA70i1AlV4x",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Key Asset Backups",
    "Description": "Covering AD/IAM, key data backed-up into cloud.",
    "Question": "Are key data and systems backed up in the cloud or offline?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, data and systems, encrypted, business risk prioritised, with an offline version",
    "Answer 2 (Full Marks 3pts)": "Yes, data, encrypted, with offline version",
    "Answer 3 (Full Marks 1pts)": "Yes, data is backed up",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recMQ14enJDI28lHP",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Password Policy",
    "Description": "Password policy….",
    "Question": "Does your organisation have a password policy?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, that mandates use of alpha-numeric and symbol characters, and is a minimum of 10 characters",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes, minimum length of 10 characters",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recM7kKyx3P0EzpNt",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Software licenses",
    "Description": "Details of software used by licenses",
    "Question": "Do you have a list of all software used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have a complete list of software used by employees.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Could put together a list of software systems.",
    "Answer 4 (No Marks 0pts)": "Could not put together a list of systems/subject to constant change.",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recMeTR2Gldzynftl",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Processes for Individual Rights",
    "Description": "Processes for rights of data subject (erasure, access, withdraw concent etc.)",
    "Question": "Does the company have processes in place to support the rights of the data subject?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Processes in place with established procedures covering requests relating to all individual rights",
    "Answer 2 (Full Marks 3pts)": "Processes in place covering requests relating to all individual rights",
    "Answer 3 (Full Marks 1pts)": "Aware of obligations but lack documented process",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recMgquYsA3Yn7WAg",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "Cyber Strategy",
    "Description": "Cyber strategy",
    "Question": "Does a documented and board approved Cyber Strategy exist?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, documented and approved by the board, with clear exec ownership and risk appetite",
    "Answer 2 (Full Marks 3pts)": "Yes documented and board approved",
    "Answer 3 (Full Marks 1pts)": "Yes documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recNTKqXoDbHYquyh",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Feedback Loop and Change",
    "Description": "Basis for making changes if there is an issue with KPIs",
    "Question": "Is there an agreed process to recify poor KPI performance?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, documented process",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Informal process",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recNzpJi8oiozHjjB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Processor or controlor",
    "Description": "Is the company a data controller or data processor?",
    "Question": "Does the company know it's role as a data processor or controller?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, with obligations and implications fully understood",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recOTEBty8QoIka3T",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "4 - RS",
    "Stage Applicable": "Series A",
    "Item": "Incident Investigation",
    "Description": "Investigation and root cause following containment",
    "Question": "Does the company conduct investigations and root cause analysis following an incident?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, full forensic analysis using a third party expert, with root cause analysis and incorporation of key learnings",
    "Answer 2 (Full Marks 3pts)": "Yes, external provider used",
    "Answer 3 (Full Marks 1pts)": "Yes, internal investigation",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recOA4WVf4bavaU9z",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Sales MI - Customer Satisfaction",
    "Description": "",
    "Question": "To what extent is customer satisfaction tracked and reported (CSAT, NPS or other)",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "CSAT exercise half-yearly, with process for immediate, off-cycle feedback to be incorporated",
    "Answer 2 (Full Marks 3pts)": "CSAT exercise annually, with process for immediate, off-cycle feedback to be incorporated",
    "Answer 3 (Full Marks 1pts)": "CSAT excercises annually",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recPUD72wh2tKDzXe",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "4 - RS",
    "Stage Applicable": "Series A",
    "Item": "Incident response plan",
    "Description": "Plan for an incident, including communications internally and externally",
    "Question": "Does an incident response plan exist?",
    "Documents Required": "IR Plan",
    "Answer 1 (Full Marks 5pts)": "Fully documented incident response plan with procedures, stakeholder maps, roles and responsibilities and internal/external communication plan exists and is updated every 6 months with annual simulation excercises",
    "Answer 2 (Full Marks 3pts)": "Documented incident response plan with procedures, stakeholder map, roles and responsibilities and comms plan.",
    "Answer 3 (Full Marks 1pts)": "Documented incident response plan",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recP6oaiaGTCLJaXt",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Board diversity (minority)",
    "Description": "",
    "Question": "% of board members who are minorities",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": ">=50%",
    "Answer 2 (Full Marks 3pts)": "1-49%",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "0%",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgU5H9aemJZsDPz",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "Risk Management",
    "Description": "ID, Assess, Treat, Track and report risks. Is Cyber included?",
    "Question": "Is risk actively discussed at board level?",
    "Documents Required": "Risk Management Documentation and Risk Register",
    "Answer 1 (Full Marks 5pts)": "Risk is actively and regularly discussed at board meetings, with risks treated, tracked and reported on periodically",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Risk is infrequently discussed at board meetings, with risks treated, tracked and reported on periodically",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgmo03myxzixBYM",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "Exec Awareness",
    "Description": "Awareness of Cyber Security risk at an exec level",
    "Question": "Are the exec team aware of cyber security risk?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, regular exec training with gold team excercises annually",
    "Answer 2 (Full Marks 3pts)": "Regular (annual) training of exec team",
    "Answer 3 (Full Marks 1pts)": "Some training in the past",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgpasoay9C9Wjvj",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Litigation",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Litigation history-founders",
    "Description": "",
    "Question": "What is the litigation history of the founders?",
    "Documents Required": "details if yes",
    "Answer 1 (Full Marks 5pts)": "no previous litigation",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "yes",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgKvAMPOpJliCcM",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "Cyber Excercises",
    "Description": "Validation of cyber capaility through simulation excercises",
    "Question": "Are the Cyber capabilities of the business excercised annually?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, simulation exercise run by an external third party",
    "Answer 2 (Full Marks 3pts)": "Yes, simulation excercises run by the internal team",
    "Answer 3 (Full Marks 1pts)": "No, but excercises have been conducted in the past",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recgXyQgS3LdLHetg",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Product",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "SLA/ Warranty",
    "Description": "Service level agreements",
    "Question": "Do you have a contract that describes the service level agreements/warranty provided with your product?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "SLA/ warranty contracts in place for product.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No documents available.",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rechZ12CLb8mdvNlc",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Contracts for computers",
    "Description": "Details of all contracts for computer systems",
    "Question": "Do you have details of all contracts regarding computer systems?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recisWNyCt7acZcBS",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Accountability",
    "Description": "Nominated",
    "Question": "Someone at Exec leadership or Board level is accountable for Data Protection",
    "Documents Required": "Extent of Accountability",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recjs4mYP4sjA05I1",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "data efficiency",
    "Description": "Does the company minimise it's risk by only collecting the data that it needs",
    "Question": "Does the company only collect the data that it requires? Is the company collecting data it doesn’t need?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Company only collects required data, and deletes unnessarary data immediately",
    "Answer 2 (Full Marks 3pts)": "Company collects more data than is required, but attempts to minimise what is collected",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Company collects data without understanding what is needed",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recj7ar7Cmt3ckjPE",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Secure by Design",
    "Description": "Cyber Security part of the product development process",
    "Question": "Is cyber security a fundamental part of new product development and change control?",
    "Documents Required": "Training Programme",
    "Answer 1 (Full Marks 5pts)": "Yes - team regularly trained in secure development and validated through security testing and forms a core part of development cycle",
    "Answer 2 (Full Marks 3pts)": "Yes, forms part of development cycle and team regularly trained",
    "Answer 3 (Full Marks 1pts)": "Forms part of development cycle",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recjh5KfE7iBlRE72",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Consent",
    "Description": "Consent when collecting personal data",
    "Question": "Does the company seek consent to use personal data?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, consent is part of user journey, with a process in place to record and manage consent",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Consent part of user journey",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reckdc2RdHACO80Cv",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Software",
    "Description": "List of software used",
    "Question": "Do you have supply chain and service agreements in place?",
    "Documents Required": "Agreements if Exists",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reclPLUVdSXMphBHM",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Strategy",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Market research",
    "Description": "Surveys and market research on company products.",
    "Question": "Do you have market research to your product's resonance in market?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, within last 3 months",
    "Answer 2 (Full Marks 3pts)": "Yes, within last 12 months",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reclRhLug9pq91xLw",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "People",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Exec Accountability",
    "Description": "Owner at excom level for Cyber risk",
    "Question": "Someone at Exec leadership or Board level is accountable for Cyber risk",
    "Documents Required": "Extent of Accountability",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reclRLMtF7v8MY8tq",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Policies & Procedures",
    "Description": "Infosec policy and procedures that are aligned with business objectives, regs and laws.",
    "Question": "Infosec policies in place that is updated annually?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Infosec policies and procedures exist, are mapped to the business objectives and regulatory requirements and are updated annually",
    "Answer 2 (Full Marks 3pts)": "Infosec policies and procedures exist, are mapped to the business objectives and regulatory requirements",
    "Answer 3 (Full Marks 1pts)": "Infosec policies and procedures exist",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reclgTZ0WPMl7dsuE",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Account/Prospect Planning",
    "Description": "",
    "Question": "How regularly are plans reviewed for Key Accounts (>10% revenue) and Key prospects (potential to be >10% revenue)?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Quarterly",
    "Answer 2 (Full Marks 3pts)": "Half Yearly",
    "Answer 3 (Full Marks 1pts)": "Annually",
    "Answer 4 (No Marks 0pts)": "Never",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recmLSy5GLasnxpzB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Seed",
    "Item": "Pentration Tests",
    "Description": "Key apps and infra",
    "Question": "Have penetration tests been conducted on key pieces of infrastructure and key applications?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, periodically by an external third party (at least annually)",
    "Answer 2 (Full Marks 3pts)": "Yes, by an external third party",
    "Answer 3 (Full Marks 1pts)": "Yes",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recmtHXnxJkGFS9lj",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Scaling customers",
    "Description": "Issues about keeping customers after the sale.",
    "Question": "Do you have a list of issues/concerns/missing features preventing the sales team from closing?",
    "Documents Required": "Issues leading to missed sales if exists",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recmVYbFxTyiN7ACR",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Sales MI - Contract Value, Revenue and Profitability",
    "Description": "",
    "Question": "To what extent is contract value sold, revenue sold and profitability achieved tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded in CRM, with weekly reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded in CRM with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded offline, with monthly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recm7e6jxzrXTKiyu",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Series A",
    "Item": "Data loss prevention",
    "Description": "Tools to prevent sensitive data leaving the corporate network",
    "Question": "Do tools exist to detect and prevent sensitive data leaving the corporate network?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recmdEBxIN0Jeq1Ix",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Consultants",
    "Description": "Consulting agreements",
    "Question": "Are consultants and independent contractors used?",
    "Documents Required": "Any consulting agreement",
    "Answer 1 (Full Marks 5pts)": "No contractors/consultants.",
    "Answer 2 (Full Marks 3pts)": "All contractors and consultants are governed by contract",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Consultants and contractors paid on an adhoc basis",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recnncuqBQKbtJOFw",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Partnerships",
    "Description": "A list of strategic relationships or partnerships.",
    "Question": "Are strategic partnerships listed and managed by a specific individual",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Details of strategic partnerships are kept and the process of new partnerships is managed by a senior team member",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Partnerships are saught out on an ad-hoc basis.",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recnfuVFJwptWhu07",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Sales MI - Cash collection",
    "Description": "",
    "Question": "To what extent is cash collection  tracked and reported?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Recorded in CRM, with weekly reviews",
    "Answer 2 (Full Marks 3pts)": "Recorded in CRM with monthly reviews",
    "Answer 3 (Full Marks 1pts)": "Recorded offline, with monthly reviews",
    "Answer 4 (No Marks 0pts)": "Not tracked",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recoNYetJiCDCgtBf",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Convertible rights",
    "Description": "",
    "Question": "Are there any convertible rights in issue?",
    "Documents Required": "review term sheet and note documents",
    "Answer 1 (Full Marks 5pts)": "",
    "Answer 2 (Full Marks 3pts)": "yes",
    "Answer 3 (Full Marks 1pts)": "no",
    "Answer 4 (No Marks 0pts)": "unsure",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recoonxMlVy3quzhD",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Distribution",
    "Description": "A list of distribution channels, marketing opportunities, and marketing risks.",
    "Question": "Do you document your most successful marketing channels?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recoJWljzBiBw5mKh",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: technology",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recp8lUYBO15kGNKi",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Annual General Meeting",
    "Description": "",
    "Question": "are annual general meetings held and records held",
    "Documents Required": "copies of shareholder communications",
    "Answer 1 (Full Marks 5pts)": "formal, to a legal standard",
    "Answer 2 (Full Marks 3pts)": "formal, not to a legal standard",
    "Answer 3 (Full Marks 1pts)": "informal meeting & notes",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recpejhIr3JEaRwXJ",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Data quality",
    "Description": "allowing data to be used to influence decision making",
    "Question": "Are the key indicators of success and leading indicators of an issue understood and monitored?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, KPIs mapped to business process and updated regularly",
    "Answer 2 (Full Marks 3pts)": "Yes, KPIs mapped to business process",
    "Answer 3 (Full Marks 1pts)": "Informally",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recqQyc9LSYisbJKg",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Lost customers",
    "Description": "A list and explanation of any major customers lost within the past two years.",
    "Question": "Do you have a list and explanation of any major customers lost within the past two years?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recqyCQvzCQLFkwnU",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "banking facilities",
    "Description": "",
    "Question": "Does your company have access to a banking credit faciilty (eg revolving/overdraft)",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "committed (>6months burn rate)",
    "Answer 2 (Full Marks 3pts)": "committed (>3months burn rate)",
    "Answer 3 (Full Marks 1pts)": "committed (up to 3months burn rate)",
    "Answer 4 (No Marks 0pts)": "nil or uncommitted",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recqA0kTZuRwyPSjs",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "third parties",
    "Description": "Sharing data with third parties",
    "Question": "Does the company have an inventory of third parties that it shares data with, as well as the lawful basis for doing so?",
    "Documents Required": "Inventory",
    "Answer 1 (Full Marks 5pts)": "Yes, and updated continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, updated periodically",
    "Answer 3 (Full Marks 1pts)": "Yes, updated infrequently",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recqWynu36tKBOG5Q",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Seed",
    "Item": "Vulnerability management",
    "Description": "risk-prioritised remediation and logging/Mapping of live vulnerabilities",
    "Question": "Are identified vulnerabilities risk-prioritised and remedied as such?",
    "Documents Required": "Programme Overview",
    "Answer 1 (Full Marks 5pts)": "Continuous programme of remediation where vulnerabilites are recorded and risk prioritised, with risk accepted at board level on unresolved issues",
    "Answer 2 (Full Marks 3pts)": "Vulnerabilities are recorded and aggregated centrally, with visibility of outstanding issues",
    "Answer 3 (Full Marks 1pts)": "Vulnerabilities are recorded in places across the business",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recrTC5kwHrGmQWrA",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "Review of user access",
    "Description": "every 3-6m",
    "Question": "Regular review of user access? (once every 3-6 months)",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "User access is reviewed every 3 months",
    "Answer 2 (Full Marks 3pts)": "User access is reivewed every 6 months",
    "Answer 3 (Full Marks 1pts)": "User access is reviewed annually",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recrt4AfZQkUYHLY7",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Sales cycles",
    "Description": "Sales cycle",
    "Question": "Is there documented understanding of the salesycle of the product?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recsoPmZstRNGXIWb",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Management accounts",
    "Description": "",
    "Question": "Are management accounts available and updated?",
    "Documents Required": "copy of accounts",
    "Answer 1 (Full Marks 5pts)": "weekly",
    "Answer 2 (Full Marks 3pts)": "monthly",
    "Answer 3 (Full Marks 1pts)": "quarterly",
    "Answer 4 (No Marks 0pts)": "half yearly",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recsytJUKONQ4HAHX",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Computer hardware",
    "Description": "List of computer hardware used",
    "Question": "Do you have a list of all computer hardware used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have a complete list of hardware used by employees.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Could put together a list of hardware systems.",
    "Answer 4 (No Marks 0pts)": "Could not put together a list of systems/subject to constant change.",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recsWzHoEdWG834gw",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Series A",
    "Item": "Intrusion prevention system",
    "Description": "IPS at entry point to internal network - alerting and logging",
    "Question": "Is any IPS solution in place at entry point to internal network, with alerting and logging enabled?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recsgxnh3tDcuUOaO",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "shareholders - annual reports",
    "Description": "",
    "Question": "are formal annual reports (and accounts) sent to shareholders",
    "Documents Required": "copies of shareholder communications",
    "Answer 1 (Full Marks 5pts)": "formal, to a legal standard",
    "Answer 2 (Full Marks 3pts)": "formal, not to a legal standard",
    "Answer 3 (Full Marks 1pts)": "informal notes",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recsjECnbIS4q9XbG",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Supply Chain",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Inputs for Value Creation",
    "Description": "Market liquidity and availability of required inputs",
    "Question": "Is there a clear view of what the company's required inputs are, and how they should protect access and against price volatility?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, documented and mitigation plan in place",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Key inputs identified and documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rectLkWEscOkAm4te",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Logging",
    "Description": "Enable logging on all systems",
    "Question": "Is logging enabled where possible?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes - logs are collected whereever possible across: external network communications, authentication and access, IT asset and configuration information and are held for at least 6 months",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Some IT logs are collected",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rectfsTx0irJBS8zk",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "CRM Tool",
    "Description": "",
    "Question": "CRM tool being used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "CRM software is used by all relevant staff, with all customer activity tracked",
    "Answer 2 (Full Marks 3pts)": "CRM System used, with customer opportunities tracked",
    "Answer 3 (Full Marks 1pts)": "Customer activity tracked ad-hoc",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recuCpQ7HQlyDLPdN",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Series A",
    "Item": "Physical Access",
    "Description": "Physical access control to buildings and facilities",
    "Question": "Is physical access managed through a regularly reviewed policy?",
    "Documents Required": "Policy",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recuevvkO6Y9yD6xq",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Business Critical Processes",
    "Description": "Mapping and understanding of critical processes",
    "Question": "Are the three most critical business processes understood and articulated with an understanding of risks and dependencies?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, formally documented and understood",
    "Answer 2 (Full Marks 3pts)": "Articulated, but not documented",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recv8yVIH6HcT1hsp",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Angel",
    "Item": "System clock synchronisation",
    "Description": "ensure time on servers/devices is synced",
    "Question": "Are system clocks synced regularly?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, daily",
    "Answer 2 (Full Marks 3pts)": "Yes, monthly",
    "Answer 3 (Full Marks 1pts)": "Yes, quarterly",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recwNGXsVAHIyTIFE",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Supply Chain",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "supplier change of control",
    "Description": "",
    "Question": "Do your supplier contracts allow them to terminate on a change of control (eg on external investment)?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "no",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "yes",
    "Answer 4 (No Marks 0pts)": "unsure",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 2,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recwl5pSC2MSIVowJ",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Leases",
    "Description": "List of leased real property",
    "Question": "Do you have a complete list of any leased property?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, or no property leased",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recw4pmlXPDEydLzo",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "People",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Awareness",
    "Description": "Training programme - general awareness, phising etc.",
    "Question": "People are trained in Cyber Risk?",
    "Documents Required": "Training Programme",
    "Answer 1 (Full Marks 5pts)": "Yes, frequent awareness training as part of a purposeful programme to ensure a strong culture of security, ensure good behaviours and drive a security by design approach",
    "Answer 2 (Full Marks 3pts)": "Some training around key issues around Cyber security - phishing, passwords, data useage, secure development etc.",
    "Answer 3 (Full Marks 1pts)": "Some communication about common issues",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recw4ASdgT7MojW3M",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "End of Life Process",
    "Description": "Managing end of life (unsupported) hardware and software",
    "Question": "Is a process in place for managing end of life assets?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Yes, documented process and procedure, regularly updated (at least annually)",
    "Answer 2 (Full Marks 3pts)": "Yes, documented process and procedure",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recxO5pE8kiN9JmFL",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "User Authentication",
    "Description": "2FA/MFA",
    "Question": "2 Factor Authentication enabled where possible?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Multi-factor authentication enabled wherever possible",
    "Answer 2 (Full Marks 3pts)": "Multi-factor authentication enabled on business critical systems",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recxSZi3uHB9jMMui",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Share rights",
    "Description": "",
    "Question": "Are there any special voting rights attached to shares?",
    "Documents Required": "Constitutional documents; SHA review; other voting rights",
    "Answer 1 (Full Marks 5pts)": "all shares equal voting rights",
    "Answer 2 (Full Marks 3pts)": "share classes with differential voting rights",
    "Answer 3 (Full Marks 1pts)": "executive vetos",
    "Answer 4 (No Marks 0pts)": "golden share",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recxAIx6bkByMsu0l",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Threat intelligence",
    "Description": "Received TI info from forums etc?",
    "Question": "Does the company receive any threat intelligence?",
    "Documents Required": "Example",
    "Answer 1 (Full Marks 5pts)": "Yes, sector specific threat intelligence is received and incorporated into monitoring process",
    "Answer 2 (Full Marks 3pts)": "The company receives regular threat intelligence and acts upon it",
    "Answer 3 (Full Marks 1pts)": "Some generic threat intelligence is reviewed",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recxbvoTrnnYKMcE3",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Location",
    "Description": "where do you hold and process data? (inc. cloud)",
    "Question": "Does the company have a clear picture of where it holds and processes data? UK? EU? US? CAN? RoW?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Fully mapped, with clear understanding of regulatory implications",
    "Answer 2 (Full Marks 3pts)": "View of where data is located, with some view of regulatory implications",
    "Answer 3 (Full Marks 1pts)": "View of where data is located, but no action",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyOz7rrBy0pFeVa",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Hiring plan",
    "Description": "Plan for new hires",
    "Question": "Do you have a plan for the necessary new hires over the next 18 months",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes that touches all levels of the company",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes that guides only senior team",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyqrRCFPpoLogea",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "insurance claims history",
    "Description": "",
    "Question": "Have you had any insurance claims rejected?",
    "Documents Required": "claims review",
    "Answer 1 (Full Marks 5pts)": "",
    "Answer 2 (Full Marks 3pts)": "no",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "yes",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyGHt1TWc92uuFu",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Series A",
    "Item": "Business Context",
    "Description": "Impact of an issue understood? Dependencies understood?",
    "Question": "Business risk understood and impact assessed?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Business impact of Cyber Risk evaluated, mapped and updated regularly",
    "Answer 2 (Full Marks 3pts)": "Business impact of Cyber Risk is evaluated and mapped",
    "Answer 3 (Full Marks 1pts)": "Business impact of cyber risk has been assessed in the past",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyIqGEhinEtioJB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Skillset: entreprenuer",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-succcesful exit",
    "Answer 2 (Full Marks 3pts)": "yes-raised funding & aquihire",
    "Answer 3 (Full Marks 1pts)": "yes-did not raise funding, no exit",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyWq5B9dGzzz1Pl",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "shareholders - monthly updates",
    "Description": "",
    "Question": "are monthly updates sent to shareholders",
    "Documents Required": "copies of shareholder communications",
    "Answer 1 (Full Marks 5pts)": "formal, to a legal standard",
    "Answer 2 (Full Marks 3pts)": "formal, not to a legal standard",
    "Answer 3 (Full Marks 1pts)": "informal notes",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 1,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyZHBn1ByHuHOQq",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Salaries",
    "Description": "Schedules of salaried hours and hourly with rates",
    "Question": "Do you have a plan for staff salaries including founder compensation and future hires?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "We have a plan for compensation",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "We do not have a plan for compensation",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recyk6AtDNEBEsA7M",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Skillset: sector expertise",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reczze78OVh3iqByV",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Market share",
    "Description": "Current market share values.",
    "Question": "Do you know your current market share?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have data that shows change in market share over time.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Have current market share but not change over time.",
    "Answer 4 (No Marks 0pts)": "Do not have the informration or market does not exist",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reczcckJhzBU2eDxi",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Breach/error/complaints procedures",
    "Description": "",
    "Question": "is there a process followed to formally document and file breaches/errors/complaints",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "informal",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recANCWq1Nhhg0Wwo",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Coordination between departments",
    "Description": "A list of coordination protocols between the sales and marketing departments.",
    "Question": "Do you have in place controls and protocols that guide the coordination between sales and marketing departments?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recAPDoJWLmPwsut6",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "ERP system",
    "Description": "",
    "Question": "ERP system being used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "ERP system implemented and fully integrated with business processes",
    "Answer 2 (Full Marks 3pts)": "ERP System in place",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recArfck58f1PpmOO",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series B",
    "Item": "Quotas",
    "Description": "Quota average",
    "Question": "Are salespeople given quotas?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, sales quotas are structured.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Informal quotas are used.",
    "Answer 4 (No Marks 0pts)": "Not yet implemented quotas",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recAvj7q5kTwFFwpu",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Series A",
    "Item": "Multi-Layer Security",
    "Description": "Are multiple layers of Security tooling deployed?",
    "Question": "Does the firm have firewalls and multiple AV/AMW vendors?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, multi-vendor, defence-in-depth strategy",
    "Answer 2 (Full Marks 3pts)": "Yes, some multi-vendor approaches",
    "Answer 3 (Full Marks 1pts)": "Single Vendor",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recA5vccGKv5aoS8Y",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Sereis A",
    "Item": "Cyber Controls Assessment",
    "Description": "Third party assurance",
    "Question": "Has the effectiveness of the businesses Cyber Security controls be evaluated by an independent third party?",
    "Documents Required": "Report",
    "Answer 1 (Full Marks 5pts)": "Yes, periodically by an external third party (at least annually)",
    "Answer 2 (Full Marks 3pts)": "Yes, by an external third party",
    "Answer 3 (Full Marks 1pts)": "Yes",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recBGiPIT84fh9nen",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "People",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Roles",
    "Description": "ID roles in team internally and that of partners",
    "Question": "RACI matrix for Cyber Security created and up to date?",
    "Documents Required": "RACI Matrix",
    "Answer 1 (Full Marks 5pts)": "Yes, encompassing internal and external parties, updated continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, encompassing internal and external parties, updated every 6 months or with key changes",
    "Answer 3 (Full Marks 1pts)": "Some roles and responsibilities are agreed and documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recBa1sjYPPnxlDdZ",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "List of customers",
    "Description": "Revenue listed by customer",
    "Question": "Do you have revenue listed by customer?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCnZ5FiZuiaJn25",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Contracts",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Asset movement",
    "Description": "List of any asset transfers or withdrawals",
    "Question": "Have there been any transfers of assets or withdrawals from the company ledger outside of paying vendors?",
    "Documents Required": "Details on withdrawals if exist",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Yes",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCFIvFrIouXfBwH",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series B",
    "Item": "Compensation",
    "Description": "Compensation and commission",
    "Question": "Is there a defined structure for the compensation, commission, or bonus structure of your salesforce?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, there is a defined and adhered to guide",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "There is a guide but it is subject to alteration on an ad-hoc basis",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCXtfVyETar6o5O",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Regulation",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Regulatory processes mapped",
    "Description": "",
    "Question": "Have you mapped the regualtory processes applicable to your business?",
    "Documents Required": "Interview, self reporting",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "Identified but not implemented",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCXwQekTCym9bnq",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "IDAM",
    "Description": "Priv/non-priv users, granting, revoking, managing, need to know.",
    "Question": "Need to know\" access, tiered account privileges and process for add, change, move, delete?",
    "Documents Required": "Policy",
    "Answer 1 (Full Marks 5pts)": "Access is granted on a \"need to know\" basis with tiered access and processes in place for adding, changing, moving and deleting users",
    "Answer 2 (Full Marks 3pts)": "Access is granted on a need to know basis with tiered access and changes are made ad-hoc",
    "Answer 3 (Full Marks 1pts)": "Privalages are tiered across users",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCf8v3sSBXd8emC",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: operations",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recCkrZ67ZtwvrtUu",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "Default user accounts",
    "Description": "remove default passwords/accounts",
    "Question": "Have all default passwords and accounts been replaced?",
    "Documents Required": "Policy",
    "Answer 1 (Full Marks 5pts)": "Yes, policy in place and enforced",
    "Answer 2 (Full Marks 3pts)": "Yes, established best practice",
    "Answer 3 (Full Marks 1pts)": "Business critical systems only",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recDSmJiDzcBsjvjW",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Series B",
    "Item": "Collective bargaining agreement",
    "Description": "Collective bargaining agreement",
    "Question": "Does the company have any agreements with collective bargaining units and/or a plan with how to deal with them.",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "We have an ongoing relationship with collective bargaining units and management has an engagement plan.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recDCMBK28mKxJHBd",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Plant and equipment",
    "Description": "",
    "Question": "Exclusive rights to key plant and equipment secure for >12 months?",
    "Documents Required": "title documents",
    "Answer 1 (Full Marks 5pts)": "owned/leased with right to renew",
    "Answer 2 (Full Marks 3pts)": "leased (no right to renew)",
    "Answer 3 (Full Marks 1pts)": "shared/hired plant and equipment",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recDFUFxypBmPhMhz",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: sales",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recETYZjuqSAzdbmG",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Pension",
    "Description": "Pension and retirement plans, stock options",
    "Question": "Do you have bespoke pension or stock option arrangements for staff/founders",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "Yes, agreements in place",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Agreements in developments",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recE8GXR3rIh34LdZ",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Employee informational materials",
    "Description": "Employee handbooks, guidelines, training manuals,",
    "Question": "What training manuals/onboarding documents or processes do you have in place for employees?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Extensive materials and/or program to facilitate onboarding of new team members.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Limited materials or programs to facilitate onboarding.",
    "Answer 4 (No Marks 0pts)": "No materials or programs to facilitate onboarding",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recFRyoccePS9hWyy",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Service interruptions",
    "Description": "Schedule of previous interruptions to computer services",
    "Question": "Do you have a list of all interruptions to systems used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have a complete list of interuptions",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Could put together a list of interuptions",
    "Answer 4 (No Marks 0pts)": "Could not put together a list of interruptions",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recFamgLZc2yUW7a5",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Patch management",
    "Description": "all systems and software are loaded with most recent security updates from vendor",
    "Question": "Do all systems and pieces of software have the most recent security updates from the vendor?",
    "Documents Required": "Policy",
    "Answer 1 (Full Marks 5pts)": "Yes, contunuous patching process to ensure most recent security updates from vendors are installed",
    "Answer 2 (Full Marks 3pts)": "Weekly patching of key systems",
    "Answer 3 (Full Marks 1pts)": "Monthly patching of key systems",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recQpa2IGk54xuWYs",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Sereis A",
    "Item": "Cyber Framework",
    "Description": "Cyber Framework = policy, standards and delivery programme",
    "Question": "Does a Cyber Framework Exist?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, documented and approved by the board, with clear exec ownership",
    "Answer 2 (Full Marks 3pts)": "Yes documented and board approved",
    "Answer 3 (Full Marks 1pts)": "Yes documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recQAXBCvr8rRgNVA",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Series A",
    "Item": "Secure Device Configuration",
    "Description": "OS and Software",
    "Question": "Is a secure build image used for new and restored devices (laptops, phones, tablets etc.)?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, documented, applied and continuously monitored for compliance",
    "Answer 2 (Full Marks 3pts)": "Yes, documented and applied and periodically checked for compliance",
    "Answer 3 (Full Marks 1pts)": "Yes, documented and applied",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recRBkfdY6SVRyOV7",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Executive team diversity (female)",
    "Description": "",
    "Question": "% of executive team members who are women",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": ">=50%",
    "Answer 2 (Full Marks 3pts)": "1-49%",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "0%",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recR5RiL8BlNIwxrn",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Deal Review/Signoff Process",
    "Description": "",
    "Question": "What controls are in place around releasing proposals to clients",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Structured deal review process, with tiered authority limits based on seniority",
    "Answer 2 (Full Marks 3pts)": "Tiered sign-off limits",
    "Answer 3 (Full Marks 1pts)": "Needs sign-off from another person",
    "Answer 4 (No Marks 0pts)": "None",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recSOuffakIiV76ze",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "4 - RS",
    "Stage Applicable": "Series A",
    "Item": "Specialist Incident Response Support",
    "Description": "Support from an expert third party in the event of an incident",
    "Question": "Does the company know who it would go to externally for support in the event of an incident?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Retained incident response provider with agreed SLAs",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Contact at IR specialist with no agreed SLA",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recSHEN1WPVdfXL7V",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Expenses",
    "Description": "Expense trends over the past five years.",
    "Question": "How do you track corporate expenses?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Accounting software is used by all staff and is approved according to internal procedures",
    "Answer 2 (Full Marks 3pts)": "Expenses are tracked and employeess/founders approve their own spending",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No tracking of expenses",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recS3nY2Ryz5HTLDL",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Regulation",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Environmental laws",
    "Description": "",
    "Question": "Does the company have a environmental policy (if applicable) in place?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 2,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recShwEH7T9RvGCml",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Seed",
    "Item": "Cloud configuration review",
    "Description": "Have the cloud instances been configured securely?",
    "Question": "Has an external review of cloud configurations been conducted?",
    "Documents Required": "Report",
    "Answer 1 (Full Marks 5pts)": "Yes, periodic reviews, and remediation of issues found",
    "Answer 2 (Full Marks 3pts)": "Yes, within the last 6 months",
    "Answer 3 (Full Marks 1pts)": "No, but within the next 6 months",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recTKhlMQs7gpxaZL",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Non-exec team ownership-investors",
    "Description": "",
    "Question": "What % of all voting rights are held by professional investors?",
    "Documents Required": "share register",
    "Answer 1 (Full Marks 5pts)": ">10; <90",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "<10; >80",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recTVRS8RRJQxN7Mx",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Litigation",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Litigation history-company",
    "Description": "",
    "Question": "What is the litigation history of the company?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "no previous litigation",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "yes",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recUxkdYilWRcbRd2",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Seed",
    "Item": "Data protection by design",
    "Description": "Incorporating Privacy/Protection by Design into business process",
    "Question": "Is data protection built into new and existing business processes and decisions?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, fundamental part of business processes and decisions",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "No, but considered",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recU6L2ad8JrzM92y",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "System appraisals",
    "Description": "Any available appraisals of systems",
    "Question": "Do you have any appraisals available of your equipment?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWOXGhZYUjP68Jz",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Skills matrix",
    "Description": "Skills Match",
    "Question": "Have you completed a skills assessment of the team currently in place",
    "Documents Required": "Skills assesment if exists",
    "Answer 1 (Full Marks 5pts)": "Yes and avaiable",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 9,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWyzZFoIlRBohya",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "shareholders - quarterly updates",
    "Description": "",
    "Question": "are quarterly reports sent to shareholders",
    "Documents Required": "copies of shareholder communications",
    "Answer 1 (Full Marks 5pts)": "formal, to a legal standard",
    "Answer 2 (Full Marks 3pts)": "formal, not to a legal standard",
    "Answer 3 (Full Marks 1pts)": "informal notes",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWAj5nvA3H4ZpC7",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Legal Risk",
    "Theme Group": "Regulation",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Regulatory approval",
    "Description": "Correspondence and documents related to regulatory approval of product line.",
    "Question": "Does your product/service require regulatory approval, if so is that process being project managed?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes it is being managed and records kept/No regulatory approval is neccesary.",
    "Answer 2 (Full Marks 3pts)": "Yes",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "The approval process is being handled informally",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWYdKp3s6yOKLOp",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Detection Technology",
    "Description": "Detection of personel",
    "Question": "Does detection technology extend to detecting people on the network?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recWZucMNj4QQ9uRc",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Board diversity (female)",
    "Description": "",
    "Question": "% of board members who are women",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": ">=50%",
    "Answer 2 (Full Marks 3pts)": "1-49%",
    "Answer 3 (Full Marks 1pts)": "1-24%",
    "Answer 4 (No Marks 0pts)": "0%",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recXPWEwILh0b0wr9",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Credit policies",
    "Description": "A description of the company’s credit policies.",
    "Question": "Do you have a description of the company’s credit policies.",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recXo9nyZR7Y3OmeN",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Audit",
    "Description": "Audit and actuarial studies and reports",
    "Question": "Has the company conducted or had conducted any audits?",
    "Documents Required": "Audits if exist",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recXqGzAMX9HQIeIB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Data in transit",
    "Description": "encrypted in transit",
    "Question": "Is data encrypted in transit?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, all data in transit is encrypted where possible/applicable",
    "Answer 2 (Full Marks 3pts)": "User data/business critical data is ecypted in transit",
    "Answer 3 (Full Marks 1pts)": "Some data is encrypted in transit",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recXHzd3l8vmjwcwO",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Regulation",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "tax advice",
    "Description": "",
    "Question": "Has the company obtained and complied with tax advice?",
    "Documents Required": "copies of tax advice",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recX6Jxhucmr38cLM",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Series B",
    "Item": "Union representation",
    "Description": "Union representation",
    "Question": "Are any members of your staff represented by a union?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes and there are agreements in place that cover their engagement",
    "Answer 4 (No Marks 0pts)": "Yes and no agreements in place",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recX8nSAgs5utRihc",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Premises",
    "Description": "",
    "Question": "exclusive rights to premises secure for >12 months",
    "Documents Required": "title documents",
    "Answer 1 (Full Marks 5pts)": "freehold/leasehold with right to renew",
    "Answer 2 (Full Marks 3pts)": "leasehold (no right to renew)",
    "Answer 3 (Full Marks 1pts)": "shared space only",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recYXz5kBL6wmubpD",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Non-essential expenses",
    "Description": "Questionable expenses that you can cut.",
    "Question": "How often do you reassess the necissity of expenses?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Quarterly",
    "Answer 2 (Full Marks 3pts)": "Semi-annually",
    "Answer 3 (Full Marks 1pts)": "Annually",
    "Answer 4 (No Marks 0pts)": "Never",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recY3lWU9I2sVMAWK",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: marketing",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recY9s4RNQ6GjB0s5",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Remote access",
    "Description": "Security around remote access",
    "Question": "Does all remote access to corporate network and business applications require multi-factor authentication?",
    "Documents Required": "Solution Detail",
    "Answer 1 (Full Marks 5pts)": "Yes, solution in place",
    "Answer 2 (Full Marks 3pts)": "Yes, internal solution",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recYhMgFUWcE9yTEr",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Regulation",
    "NIST Reference": "GDPR",
    "Stage Applicable": "Angel",
    "Item": "Lawful basis",
    "Description": "Lawful basis for processing",
    "Question": "Does the company know what it's lawful basis for processing personal data is?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, understood and documented for all areas of the business",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Understood by not documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recZ65pkW6GAEPJ4O",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Product",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Products and services offered",
    "Description": "Lists of products and services offered.",
    "Question": "Do you have a complete list of all products and services offered or in development?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have a complete list of all products and services offered or in development",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Do not have a complete list of all products and services offered or in development",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recZhfyOVQa0R1HOE",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Network Baseline",
    "Description": "Baseline patterns for netowrk and comms activity",
    "Question": "Is baseline network activity recorded and documented?",
    "Documents Required": "Example",
    "Answer 1 (Full Marks 5pts)": "Yes, documented and continuously monitored and validated",
    "Answer 2 (Full Marks 3pts)": "Yes, documented and validated periodically (monthly)",
    "Answer 3 (Full Marks 1pts)": "Yes, documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recZjFNOiBOmiBZPr",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Compliance framework",
    "Description": "",
    "Question": "is there a formal compliance framework / policy",
    "Documents Required": "copy of policy",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "Identified but not implemented",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec0mzn3U6F2WHse7",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: finance",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec0y6N5zvFUAX2gS",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Classifying Data",
    "Description": "Controls to classify the sensitivity of information/data",
    "Question": "Do controls exist to classify the sensitivity of data and information?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Yes, documented classification process and procedure, regularly updated (at least annually)",
    "Answer 2 (Full Marks 3pts)": "Yes, documented classification process and procedure",
    "Answer 3 (Full Marks 1pts)": "Sensitive/personal data flagged and documented",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec05Q7Yp1wNnTEcH",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Vulnerability Scanning Tool",
    "Description": "all systems on network scanned quarterly at least",
    "Question": "Are all business critcal systems scanned for vulnerabilities at least quarterly",
    "Documents Required": "Tool Details",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec1wTp2BiMnlucuO",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Supply Chain",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Supply and service agreements",
    "Description": "Supply chain and service agreements.",
    "Question": "Do you have agreements with supplier that ensure you can continue to provide your product?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Critical suppliers and contractors are covered by binding agreements.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Some service providers are covered by formal agrements",
    "Answer 4 (No Marks 0pts)": "Arrangements with suppliers are informal",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec1xEImBHw6twVu7",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Series A",
    "Item": "Review logs",
    "Description": "review for anomalies",
    "Question": "Are logs regularly reviewed for anomalies?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Logs are continuously monitored for anomolies",
    "Answer 2 (Full Marks 3pts)": "Logs are regularly reviewed for anomolies daily",
    "Answer 3 (Full Marks 1pts)": "Logs are reviewed periodically",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec1Fbc56PZJgbIyB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "management / internal Complaints",
    "Description": "",
    "Question": "is there monthly reporting of management / internal complaints to exec team and directors",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec2yTiBtA1J87aEg",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Supply Chain",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Purchasing policies",
    "Description": "A description of the company’s purchasing policies.",
    "Question": "Do you have a plan for the necessary new hires over the next 18 months",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec2e5eZMoU4Iezia",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Financials",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Cost structure",
    "Description": "Profitability and cost structure, including:",
    "Question": "Do you know the cost structures of your product? (unit costs, elements of burn rate)",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Have cost structures and change over time.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Have current cost structures but not change over time.",
    "Answer 4 (No Marks 0pts)": "Do not have cost structures",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec3tw1deVokqPb0w",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Companies house filings",
    "Description": "",
    "Question": "Are public filings all up to date?",
    "Documents Required": "Companies house / equivalent",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "partially (up to 1 month out of date)",
    "Answer 3 (Full Marks 1pts)": "partially (next 3 months out of date)",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec4xWvQ7eNCW1nUg",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Databases",
    "Description": "List of databases used",
    "Question": "Do you have a list of all database systems used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "In progress (<1 month)",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec4zScP8hFQ0Ga85",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "Firewall",
    "Description": "between internet and internal network and Web-App Firewalls for web facing apps",
    "Question": "Are firewalls in place?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Yes, multi-vendor, defence-in-depth strategy",
    "Answer 2 (Full Marks 3pts)": "Yes",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec5v3Fc3yoHaqRSB",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Angel",
    "Item": "Data Inventory",
    "Description": "What is held, how sensitive it is, and where",
    "Question": "Does an inventory of personal data (PII) exist?",
    "Documents Required": "Inventory",
    "Answer 1 (Full Marks 5pts)": "Yes, and updated continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, updated periodically",
    "Answer 3 (Full Marks 1pts)": "Yes, updated infrequently",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec6rtOW7TdgNApEp",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Independant members",
    "Description": "",
    "Question": "Is there an independent director? the",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec6z450Smxi3tEME",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Measurement of Quality",
    "Description": "Understanding how clients percieve value and quality",
    "Question": "Does the company know what is important to its customers when evaluating the quality of the offering?",
    "Documents Required": "Relevant Documentation",
    "Answer 1 (Full Marks 5pts)": "Regular feedback sessions with clients, and value drivers understood and documented",
    "Answer 2 (Full Marks 3pts)": "Value drivers understood and documented  and subtantiated by clients",
    "Answer 3 (Full Marks 1pts)": "Documented assumed value drivers",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": "",
    "Weighted Score": 0,
    "Weighted Potential Score": 0,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec6AMaDRJXsIQKk3",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Seed",
    "Item": "Encrypted device data",
    "Description": "data stored on laptops/phones/tablets etc",
    "Question": "Is device data encrypted?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes, all devices",
    "Answer 2 (Full Marks 3pts)": "Yes, most devices",
    "Answer 3 (Full Marks 1pts)": "Business critical devices only",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec6J2emMjRyOX1zr",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Employee loans, advances",
    "Description": "Employee loans, advances",
    "Question": "Do any member of staff or founders have loans or advances from the company?",
    "Documents Required": "Details of loans if applicable",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Yes",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec7Mxn65Y2xqOe3a",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Tax filings",
    "Description": "",
    "Question": "Are all tax filings up to date?",
    "Documents Required": "Interview, self reporting",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec7OzNIOJ4KyCbds",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Constitutional documents",
    "Description": "",
    "Question": "Do you have a copy of your constitutional documents",
    "Documents Required": "M&As",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec7ky10Y8lVwHgiP",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Customers and Markets",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "customer change of control",
    "Description": "",
    "Question": "Do your customer contracts allow them to terminate on a change of control (eg on external investment)?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "no",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "yes",
    "Answer 4 (No Marks 0pts)": "unsure",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec8PF2tA6U49krWQ",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Payroll System",
    "Description": "",
    "Question": "Payroll system being used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec8r1w2cVo3Is2hP",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Veto rights - exec appointees",
    "Description": "",
    "Question": "all board members with same voting rights (with no vetoes)",
    "Documents Required": "SHA review",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 15,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec8AuBRZifbN8Sof",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "5 - RE",
    "Stage Applicable": "Series A",
    "Item": "Continuous Improvement",
    "Description": "Learning from incidents and continuous improvement",
    "Question": "Does a process exist to ensure that the organisation learns from cyber incidents?",
    "Documents Required": "Process Documents",
    "Answer 1 (Full Marks 5pts)": "Established process for investigation and debrief following all incidents and events of significance to understand root cause and make changes to improve",
    "Answer 2 (Full Marks 3pts)": "Debriefing following all incidents and events of significance to understand root cause and make changes to improve",
    "Answer 3 (Full Marks 1pts)": "Some review post incident or event of significance with learnings communicated",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec9RY5AzQrmTcXcq",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Accounting/Ledger System",
    "Description": "",
    "Question": "Financial Ops systems being used?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "All internal accounting is conducted through a general ledger/accounting system",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Accounting tooling used",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": "",
    "Importance Factor": "",
    "Weighted Score": "",
    "Weighted Potential Score": "",
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec9lwYxzf1A6Hblk",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Skillset: HR",
    "Description": "",
    "Question": "Does the executive team have relevant skillset",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes-professionally",
    "Answer 2 (Full Marks 3pts)": "yes-education",
    "Answer 3 (Full Marks 1pts)": "partially",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 3,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "rec9l5aq5nI1sF4sN",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Processes",
    "NIST Reference": "3 - DE",
    "Stage Applicable": "Seed",
    "Item": "Monitoring",
    "Description": "Monitoring and alerting for anomalous events/activities",
    "Question": "Does the business have the ability to monitor for anomalous events and activities?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Environment is continuously monitored monitored for anomolies and events",
    "Answer 2 (Full Marks 3pts)": "Environment is monitored daily for anomolies and events",
    "Answer 3 (Full Marks 1pts)": "Environment is periodically monitored monitored for anomolies and events",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recalXrJB5aP1pz4Y",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "Asset Risk",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Mortgages",
    "Description": "List of property mortgages",
    "Question": "Does the company have any loans on properties?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "Yes, less than 10 year payment plan on property",
    "Answer 4 (No Marks 0pts)": "Yes, ten plus year payment plan on property",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recaqqf1V47GFOuBY",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Non-exec team ownership-former employees",
    "Description": "",
    "Question": "What % of all voting rights are held by persons who are former employees or founders?",
    "Documents Required": "share register",
    "Answer 1 (Full Marks 5pts)": "0%",
    "Answer 2 (Full Marks 3pts)": "1-24%",
    "Answer 3 (Full Marks 1pts)": "25-49%",
    "Answer 4 (No Marks 0pts)": "50-100%",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recayWFIyiJ8qTVw8",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Conflicts disclosure/register",
    "Description": "",
    "Question": "is there a process followed to formally disclose and record conflicts of interest",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "informal",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recaEK8nYZAOyRDhE",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Board minutes",
    "Description": "",
    "Question": "is there a process followed to formally document and file board minutes",
    "Documents Required": "Minute book",
    "Answer 1 (Full Marks 5pts)": "formal, to a legal standard",
    "Answer 2 (Full Marks 3pts)": "formal, not to a legal standard",
    "Answer 3 (Full Marks 1pts)": "informal (notes/emails)",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 0,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recbPyQKD44lylFWt",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Plant Property Equipment",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Asset Inventory",
    "Description": "Hardware, Software, Services in up to date inventory",
    "Question": "Does an inventory of Hardware, software and services exist?",
    "Documents Required": "Inventory",
    "Answer 1 (Full Marks 5pts)": "Yes, and updated continuously",
    "Answer 2 (Full Marks 3pts)": "Yes, updated periodically",
    "Answer 3 (Full Marks 1pts)": "Yes, updated infrequently",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recbE0tKoCI8MAVTH",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Asset Risk",
    "Theme Group": "Intellectual Property",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "IP registration / protection",
    "Description": "",
    "Question": "Has the company registered/patented or otherwise protected all key intellectual property in key markets?",
    "Documents Required": "registrations",
    "Answer 1 (Full Marks 5pts)": "yes, all registered or patented as applicable",
    "Answer 2 (Full Marks 3pts)": "yes, some IP registered or patented",
    "Answer 3 (Full Marks 1pts)": "applicatin(s) pending",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recbdTVhIl2u9g9Q5",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Employment agreements",
    "Description": "Employment agreements and vesting schedules",
    "Question": "What employee agreements are in place?",
    "Documents Required": "Employment agreements",
    "Answer 1 (Full Marks 5pts)": "All employees are under signed agreement with vesting schedule",
    "Answer 2 (Full Marks 3pts)": "Most employees are under a signed agreement with a vesting schedule",
    "Answer 3 (Full Marks 1pts)": "Most employees are under a signed agreement with no vesting of options",
    "Answer 4 (No Marks 0pts)": "Employee agreements are informal or not part of a contract",
    "Potential Score": 5,
    "Importance Factor": 1,
    "Weighted Score": 5,
    "Weighted Potential Score": 5,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recbfmf0nUPmlJw23",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Shareholders' agreement",
    "Description": "",
    "Question": "if a shareholders' agreement is in place, is it suitable for professional investors?",
    "Documents Required": "SHA review",
    "Answer 1 (Full Marks 5pts)": "bespoke SHA, favourable to investors",
    "Answer 2 (Full Marks 3pts)": "standard SHA",
    "Answer 3 (Full Marks 1pts)": "bespoke SHA, unfavourable for investors",
    "Answer 4 (No Marks 0pts)": "no SHA",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "reccKzk4NWVhnDHMN",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Corporate Records",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Non-exec team ownership-family & friends",
    "Description": "",
    "Question": "What % of all voting rights are held by friends and family of founding team?",
    "Documents Required": "share register",
    "Answer 1 (Full Marks 5pts)": "0%",
    "Answer 2 (Full Marks 3pts)": "1-24%",
    "Answer 3 (Full Marks 1pts)": "25-49%",
    "Answer 4 (No Marks 0pts)": "50-100%",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recdCb8ms0YoB32oC",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "1 - ID",
    "Stage Applicable": "Seed",
    "Item": "Background Checks",
    "Description": "Background checks conducted on new joiners and on job changes",
    "Question": "Are background checks conducted on new joiners and movers?",
    "Documents Required": "Example",
    "Answer 1 (Full Marks 5pts)": "Yes - comprehensive, for all staff",
    "Answer 2 (Full Marks 3pts)": "Yes - basic, all staff",
    "Answer 3 (Full Marks 1pts)": "Senior management only",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 3,
    "Weighted Score": 0,
    "Weighted Potential Score": 15,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recdHVAsF1Q6Yu0jb",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Executive team diversity (minority)",
    "Description": "",
    "Question": "% of executive team members who are minorities",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": ">=50%",
    "Answer 2 (Full Marks 3pts)": "1-49%",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "0%",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "receMwLuLYR4KGmUw",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Josh",
    "Risk Module": "Cyber & Data Privacy",
    "Theme Group": "Employees",
    "NIST Reference": "2 - PR",
    "Stage Applicable": "Angel",
    "Item": "Admin accounts",
    "Description": "Secondary admin accounts",
    "Question": "Administrators have secondary admin accounts, rather than their normal credentials?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "Do system administrators have secondary accounts for use only as administrator credetials",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recerHpRLPgWpLKIV",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Legal Risk",
    "Theme Group": "Regulation",
    "NIST Reference": "",
    "Stage Applicable": "Series A",
    "Item": "Health and safety",
    "Description": "",
    "Question": "Does the company have a health and safety policy in place?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "yes",
    "Answer 2 (Full Marks 3pts)": "On track to implement in next 3 months",
    "Answer 3 (Full Marks 1pts)": "On track to implement in next 6 months",
    "Answer 4 (No Marks 0pts)": "no",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 2,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recfSZb65LgN4W6O8",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Senior Team",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Board size",
    "Description": "",
    "Question": "How many directors does the company have?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": ">3",
    "Answer 2 (Full Marks 3pts)": 3,
    "Answer 3 (Full Marks 1pts)": 2,
    "Answer 4 (No Marks 0pts)": 1,
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recfp1puv2yL3578G",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "MI & Operational Process",
    "Theme Group": "Supply Chain",
    "NIST Reference": "",
    "Stage Applicable": "Seed",
    "Item": "Suppliers",
    "Description": "A list of the top 10 suppliers, as well as business deals within the past two years.",
    "Question": "Is a list maintained of the top 10 supplies the business has used in at least the last two years?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "A list of key suppliers is maintained and updated.",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "No list of suppliers is managed.",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 10,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recfYwOF5bebn630e",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Adam",
    "Risk Module": "People Risk",
    "Theme Group": "Employees",
    "NIST Reference": "",
    "Stage Applicable": "Series B",
    "Item": "Labour disputes",
    "Description": "Description of labour disputes, request for arbitration, mediation, etc.",
    "Question": "Are their any ongoing disputes with labour groups or others that have required mediation or arbitrations?",
    "Documents Required": "",
    "Answer 1 (Full Marks 5pts)": "No",
    "Answer 2 (Full Marks 3pts)": "",
    "Answer 3 (Full Marks 1pts)": "",
    "Answer 4 (No Marks 0pts)": "Yes",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 6,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  },
  {
    "Record ID": "recf8SNzfb15bJ56y",
    "Vanilla VC Question Stage": "Risk",
    "Owner": "Paul",
    "Risk Module": "Corporate Governance",
    "Theme Group": "Processes",
    "NIST Reference": "",
    "Stage Applicable": "Angel",
    "Item": "Board meetings frequency",
    "Description": "",
    "Question": "How often are board meetings?",
    "Documents Required": "Minute book",
    "Answer 1 (Full Marks 5pts)": "monthly",
    "Answer 2 (Full Marks 3pts)": "quarterly",
    "Answer 3 (Full Marks 1pts)": "half-yearly",
    "Answer 4 (No Marks 0pts)": "annually",
    "Potential Score": 5,
    "Importance Factor": 2,
    "Weighted Score": 0,
    "Weighted Potential Score": 10,
    "Responses": "",
    "Requires attachment": ""
  }
];

mock.onGet('/api/questions').reply(() => {
  

  return [200, { questions }];
});

mock.onGet('/api/questions/1').reply(() => {
  const invoice = {
    id: '5ecb86785312dcc69b5799ad',
    currency: '$',
    customer: {
      address: '271 Richmond Rd, Grey Lynn, Auckland 1022, New Zealand',
      company: 'Countdown Grey Lynn',
      email: 'contact@anahenisky.io',
      name: 'Ana Henisky',
      taxId: '6934656584231'
    },
    dueDate: moment()
      .toDate()
      .getTime(),
    issueDate: moment()
      .subtract(1, 'hours')
      .toDate()
      .getTime(),
    items: [
      {
        id: '5ecb8694db1760a701dfbf74',
        currency: '$',
        description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
        unitAmount: 55.50
      }
    ],
    number: 'DEV-9483',
    status: 'paid',
    subtotalAmount: 50.00,
    taxAmount: 5.50,
    totalAmount: 55.50
  }

  return [200, { invoice }];
});



