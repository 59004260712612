import React from // useState,
// useEffect,
// useCallback
'react';
import {
  // AppBar,
  // Box,
  // Hidden,
  // Toolbar,
  // Tooltip,
  // makeStyles,
  // Grid,
  // SvgIcon,
  // Card,
  // CardContent,
  Typography
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

// import {
//   getTargets
//   // updateEvent,
//   // selectEvent,
//   // selectRange,
//   // openModal,
//   // closeModal
// } from 'src/slices/targets';

/**
 * This is the PDF layout that is included of the report.
 * @param   {string} application The class and style
 * @param   {string} blackHolder This is the question
 * @param   {string} redHolder This is the question
 * @param   {string} profile This is the question
 * @return  {string} display This displays the question with the associated responses. When clicked, it logs the response.
 */
import { modulecolor } from 'src/constants';

/**
 * This takes a questions and returns the highest possible points.
 * @param   {object} question This is the question that will be inspected
 * @return  {object} returnPossiblePoints This is points as a string
 */
export const returnPossiblePoints = question => {
  if (question.a1 !== undefined) {
    return question.a1.points;
  } else if (question.a2 !== undefined) {
    return question.a2.points;
  } else if (question.a3 !== undefined) {
    return question.a3.points;
  } else if (question.a4 !== undefined) {
    return question.a4.points;
  }
};

/**
 * This takes a score and returns the letter grade
 * @param   {string} score This is the score to be converted
 * @return  {object} LetterGrade This is the letter grade
 */
export const getLetterGradeFromScore = score => {
  // console.log(score);

  score = parseInt(score);

  if (score >= 97) {
    return 'A+';
  } else if (score >= 93) {
    // code block
    return 'A';
  } else if (score >= 90) {
    // code block
    return 'A-';
  } else if (score >= 87) {
    // code block
    return 'B+';
  } else if (score >= 83) {
    // code block
    return 'B';
  } else if (score >= 80) {
    // code block
    return 'B-';
  } else if (score >= 77) {
    // code block
    return 'C+';
  } else if (score >= 73) {
    // code block
    return 'C';
  } else if (score >= 70) {
    // code block
    return 'C-';
  } else if (score >= 67) {
    // code block
    return 'D+';
  } else if (score >= 63) {
    // code block
    return 'D';
  } else if (score >= 60) {
    // code block
    return 'D-';
  } else if (score >= 57) {
    // code bloc
    return 'F+';
  } else if (score >= 53) {
    // code block
    return 'F';
  } else if (score >= 0) {
    return 'F';
  } else {
    return 'err';
  }
  // code block
};

export const roundToHalf = value => {
  let converted = parseFloat(value); // Make sure we have a number
  let decimal = converted - parseInt(converted, 10);
  decimal = Math.round(decimal * 10);
  if (decimal === 5) {
    return parseInt(converted, 10) + 0.5;
  }
  if (decimal < 3 || decimal > 7) {
    return Math.round(converted);
  } else {
    return parseInt(converted, 10) + 0.5;
  }
};

export const flagCopy = value => {
  if (value < 0.1) {
    return 'Your company does not have any significant issues that will immediately disqualify your company in the eyes of an investor.';
  } else if (value < 0.2) {
    return 'There are a few issues that will immediately disqualify your company in the eyes of an investor. We recommend that you address these issues to help expedite your raise.';
  } else if (value < 0.4) {
    return 'There are significant issues that will immediately disqualify your company in the eyes of an investor. We recommend that you address these issues to help expedite your raise.';
  } else if (value < 0.5) {
    return 'There are numerous and significant issues that will immediately disqualify your company in the eyes of an investor. We recommend that you address these issues to help expedite your raise.';
  } else {
    return 'Numerous issues identified in the assement will prevent professional investors from investing in your company. These issues are frequent enough that they pose an existential threat to your raise.';
  }
};

export const returnModuleColor = questionmodule => {
  switch (questionmodule) {
    case 'Cyber & Data Privacy':
      // code block
      // console.log('Cyber & Data Privacy');
      return modulecolor.cyber;
      // holderEvents[i]['color'] = '#0079BF';
      // break;
    case 'Corporate Governance':
      // console.log('Corporate Governance');
      return modulecolor.governance;
      // break;
    case 'Legal Risk':
      // console.log('Legal');
      return modulecolor.legal;
      // break;
    case 'Asset Risk':
      // console.log('Asset');
      return modulecolor.asset;
      // break;
    case 'MI & Operational Process':
      // console.log('MI & Operational');
      return modulecolor.mi;
      // break;
    case 'People Risk':
      // console.log('People');
      return modulecolor.people;
      // break;
    default:
      console.log(questionmodule);
      return '#FFFFFF';
  }
};

export const returnFontColorComplete = question => {
  if (question.complete === true) {
    // return "#000000";
    return '#FFFFFF';
  } else {
    return '#000000';
  }
};

export const returnModuleColorComplete = question => {
  if (question.complete === false) {
    switch (question.questions.module) {
      case 'Cyber & Data Privacy':
        // code block
        // console.log('Cyber & Data Privacy');
        return modulecolor.cyber;
        // holderEvents[i]['color'] = '#0079BF';
        // break;
      case 'Corporate Governance':
        // console.log('Corporate Governance');
        return modulecolor.governance;
        // break;
      case 'Legal Risk':
        // console.log('Legal');
        return modulecolor.legal;
        // break;
      case 'Asset Risk':
        // console.log('Asset');
        return modulecolor.asset;
        // break;
      case 'MI & Operational Process':
        // console.log('MI & Operational');
        return modulecolor.mi;
        // break;
      case 'People Risk':
        // console.log('People');
        return modulecolor.people;
        // break;
      default:
        console.log(question);
        return '#FFFFFF';
    }
  } else {
    return '#002147';
  }
};

export const getWarningsFromApplication = application => {
  //sort actions by priority number

  // console.log("getWarningsFromApplication");
  // console.log(application);

  application['actions'] = application['actions'].sort(
    (a, b) => a.priority - b.priority
  );

  //remove actions with a -1 priority which means they should not be done
  application['actions'] = application['actions'].filter(function(itm) {
    return itm.priority > -1;
  });

  // for (let action in application['actions']) {
  //   // code block to be executed
  //   let obj = application['questions'].find(
  //     o => o.id === application['actions'][action]['question']
  //   );
  //   // console.log(obj);
  //   // application['actions'][action]['fullquestion'] = obj;
  // }

  const returnQuestion = action => {
    // let obj = application['questions'].find(o => o.id === action['question']);
    // console.log(obj);
    return application['questions'].find(o => o.id === action['question']);
  };

  const returnAction = question => {
    //let obj = application['actions'].find(o => o.question === question['id']);
    // console.log(obj);
    return application['actions'].find(o => o.question === question['id']);
  };

  // let today = new Date();

  let redHolder = [];
  let blackHolder = [];

  for (let i in application['questions']) {
    if (application['questions'][i].points === '0') {
      switch (application['questions'][i].warnings) {
        case 'Black ball':
          // console.log(application['questions'][i]);
          let blacktemp = returnAction(application['questions'][i]);

          // console.log(blacktemp);

          if (blacktemp !== undefined) {
            blacktemp['questions'] = application['questions'][i];
            blackHolder.push(blacktemp);
          }

          // console.log(blacktemp);
          break;
        case 'Red flag':
          // console.log(application['questions'][i]);
          let redtemp = returnAction(application['questions'][i]);

          if (redtemp !== undefined) {
            redtemp['questions'] = application['questions'][i];
            redHolder.push(redtemp);
          }

          // if (redtemp.priority > 1){
          //   redHolder.push(redtemp);
          // }

          // console.log(redtemp);
          break;
        default:
        // code block
      }
    }
  }

  redHolder = redHolder.sort((a, b) => a.priority - b.priority);

  blackHolder = blackHolder.sort((a, b) => a.priority - b.priority);

  for (let i in application.actions) {
    application.actions[i]['questions'] = returnQuestion(
      application.actions[i]
    );
  }

  return [blackHolder, redHolder];
};

export const valBetween = (v, min, max) => {
  return Math.min(max, Math.max(min, v));
};

export const stargrade = (score, complete) => {
  return (
    <Typography
      component={'p'}
      variant="body1"
      color={'textPrimary'}
      gutterBottom
    >
      <div style={{ position: 'static' }}>
        <Rating
          name="read-only"
          value={score}
          precision={0.5}
          readOnly
          size="large"
        />
        <Rating
          name="read-only"
          style={{
            position: 'relative',
            //second one is right fourth one is left
            clipPath:
              'inset(0px ' +
              (5 - complete) * 2 * 15 +
              'px 0px ' +
              score * 2 * 15 +
              'px)',
            color: '#002147',
            // marginTop: '-37px'
            marginLeft: '-150px'
            // iconFilled:"black"
          }}
          value={5}
          max={5}
          precision={0.5}
          readOnly
          size="large"
        />
      </div>
      {/* <StyledRating
                  name="customized-color"
                  defaultValue={2}
                  getLabelText={value =>
                    `${value} Heart${value !== 1 ? 's' : ''}`
                  }
                  precision={0.5}
                  icon={<StarIcon fontSize="inherit" />}
                  size="large"
                />*/}
    </Typography>
  );
};

export const getScores = (targets, holdapplication) => {
  let application;

  if (holdapplication !== undefined) {
    application = JSON.parse(JSON.stringify(holdapplication));
  } else {
    return false;
  }

  // console.log("targets");
  // console.log(targets);

  let targetRound = {};
  switch (application.funding_stage) {
    case 'Angel':
      // code block
      targetRound = targets.angel;
      break;
    case 'Seed':
      // code block
      targetRound = targets.seed;
      break;
    case 'Series A':
      // code block
      targetRound = targets.seriesA;
      break;
    case 'Series B':
      // code block
      targetRound = targets.seriesB;
      break;
    case 'Series C':
      // code block
      targetRound = targets.seriesC;
      break;
    case 'Series D':
      // code block
      targetRound = targets.seriesD;
      break;
    default:
    // code block
  }

  // Before complete actions
  ////////////////////////////////
  let holder = getWarningsFromApplication(application);
  let blackHolder = holder[0];
  let redHolder = holder[1];
  let flagDetraction = blackHolder.length * 0.2 + redHolder.length * 0.1;

  let governanceScore = valBetween(
    application.scores.governance / targetRound.governance,
    0,
    1
  );
  let cyberScore = valBetween(
    application.scores.cyber / targetRound.cyber,
    0,
    1
  );
  let legalScore = valBetween(
    application.scores.legal / targetRound.legal,
    0,
    1
  );
  let peopleScore = valBetween(
    application.scores.people / targetRound.people,
    0,
    1
  );
  let assetScore = valBetween(
    application.scores.asset / targetRound.asset,
    0,
    1
  );
  let miScore = valBetween(application.scores.mi / targetRound.mi, 0, 1);

  let minCategoryScore = Math.min(
    governanceScore,
    cyberScore,
    legalScore,
    peopleScore,
    assetScore,
    miScore
  );

  let rawScore =
    (governanceScore +
      cyberScore +
      legalScore +
      peopleScore +
      assetScore +
      miScore) /
    6;

  let score = (valBetween(rawScore - flagDetraction, 0, 1) * 100) / 20;
  score = roundToHalf(score);

  // After complete actions
  ////////////////////////////////

  let completeActions = application.actions.filter(
    black => black.complete === true
  );
  // console.log(completeActions.length);
  let completeActionsData = {};

  let completeScores = {
    'Cyber & Data Privacy': 0,
    'Corporate Governance': 0,
    'Legal Risk': 0,
    'Asset Risk': 0,
    'MI & Operational Process': 0,
    'People Risk': 0
  };

  for (let i in application.questions) {
    // console.log(returnPossiblePoints(application.actions[i].questions));

    const returnAction = question => {
      //let obj = application['actions'].find(o => o.question === question['id']);
      // console.log(obj);
      return application['actions'].find(o => o.question === question['id']);
    };

    let holderActions = returnAction(application['questions'][i]);

    if (holderActions === undefined) {
      completeScores[application.questions[i].module] =
        completeScores[application.questions[i].module] +
        parseInt(application.questions[i].points);
    } else {
      if (holderActions.complete) {
        completeScores[holderActions.questions.module] =
          completeScores[holderActions.questions.module] +
          returnPossiblePoints(holderActions.questions);
      } else {
        completeScores[holderActions.questions.module] =
          completeScores[holderActions.questions.module] +
          parseInt(holderActions.questions.points);
      }
    }
  }

  let inCompleteBlack = blackHolder.filter(black => black.complete === false);
  // console.log(inCompleteBlack);
  let inCompleteRed = redHolder.filter(black => black.complete === false);

  let flagDetractionComplete =
    inCompleteBlack.length * 0.2 + inCompleteRed.length * 0.1;

  let governanceScoreComplete = valBetween(
    completeScores['Corporate Governance'] / targetRound.governance,
    0,
    1
  );

  let cyberScoreComplete = valBetween(
    completeScores['Cyber & Data Privacy'] / targetRound.cyber,
    0,
    1
  );
  let legalScoreComplete = valBetween(
    completeScores['Legal Risk'] / targetRound.legal,
    0,
    1
  );
  let peopleScoreComplete = valBetween(
    completeScores['People Risk'] / targetRound.people,
    0,
    1
  );
  let assetScoreComplete = valBetween(
    completeScores['Asset Risk'] / targetRound.asset,
    0,
    1
  );
  let miScoreComplete = valBetween(
    completeScores['MI & Operational Process'] / targetRound.mi,
    0,
    1
  );

  let minCategoryScoreComplete = Math.min(
    governanceScoreComplete,
    cyberScoreComplete,
    legalScoreComplete,
    peopleScoreComplete,
    assetScoreComplete,
    miScoreComplete
  );

  // console.log(minCategoryScoreComplete);

  let rawScoreComplete =
    (governanceScoreComplete +
      cyberScoreComplete +
      legalScoreComplete +
      peopleScoreComplete +
      assetScoreComplete +
      miScoreComplete) /
    6;

  // console.log(rawScoreComplete);

  let scoreComplete =
    (valBetween(rawScoreComplete - flagDetractionComplete, 0, 1) * 100) / 20;
  scoreComplete = roundToHalf(scoreComplete);

  return {
    score: score,
    scoreComplete: scoreComplete,
    flagDetractionComplete: flagDetractionComplete,
    minCategoryScoreComplete: minCategoryScoreComplete
  };
};
