import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import firebase from 'src/lib/firebase';
// import moment from 'moment';

const initialState = {
  notifications: []
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    getNotifications(state, action) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    }
  }
});

export const reducer = slice.reducer;

// {
//     id: '5e8883f1b51cc1956a5a1ec0',
//     createdAt: moment()
//       .subtract(2, 'hours')
//       .toDate()
//       .getTime(),
//     description: 'Dummy text',
//     title: 'Your order is placed',
//     type: 'order_placed'
//   },

export const getNotifications = () => async dispatch => {
  let response = await axios.get('/api/notifications');

  const targetsSnapshot = await firebase
    .firestore()
    .collection('notifications')
    .where('published', '==', true)
    // .startAt("2019-01-05")
    .get();

  let targetsHolder = [];

  let tempTargets = targetsSnapshot.docs.map(doc => {
    // console.log(doc.id);
    // var tempId = doc.id;
    targetsHolder[doc.id] = doc.data();
    return { id: doc.id, ...doc.data() };
  });

  //const messaging = firebase.messaging();

  tempTargets = {
    notifications: tempTargets
  };

  // console.log(response.data);

  dispatch(slice.actions.getNotifications(tempTargets));
};

export default slice;
