// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import firebase from 'firebase';

///working 1
import { firebaseConfig } from 'src/config';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/analytics';
import 'firebase/compat/storage';
import 'firebase/performance';
import 'firebase/compat/app-check';
import 'firebase/compat/messaging';
import 'firebase/compat/remote-config';

// import "firebase/compat/appcheck";

// import { collection, doc, setDoc } from "firebase/firestore";
// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// const functions = require('firebase-functions');

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.

firebase.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN_FROM_CI;

// firebase.appCheck().activate(/* site key or provider */);

firebase.appCheck().activate(
  '6Lfr_9kdAAAAAIgiMs4MW6kNK8_PyEq2vQnlCKnA',
//   'FD90ED14-F79A-4667-A91A-2E42C373F96A', //local server
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  true
);

//end of working 1

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { collection, addDoc } from "firebase/firestore";
// import { getFirestore } from "firebase/firestore"
// import { doc, setDoc } from "firebase/firestore";
// import { query, where } from "firebase/firestore";

// // Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCeVNi4f7CYzmVggWn9aeZSah9Cf9KIGdM",
//   authDomain: "osneycapital.firebaseapp.com",
//   databaseURL: "https://osneycapital.firebaseio.com",
//   projectId: "osneycapital",
//   storageBucket: "osneycapital.appspot.com",
//   messagingSenderId: "11494763716",
//   appId: "1:11494763716:web:1cac67fe8f8a0c3f17ed91",
//   measurementId: "G-K4XXDN7VS3"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// import "firebase/performance";

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
// import 'firebase/messaging';
// import 'firebase/functions';
// import 'firebase/firestore';
// import 'firebase/storage';

// import { initializeApp } from "firebase/app"
// import { getFirestore } from "firebase/firestore"
// const firebase = initializeApp(firebaseConfig);

// const db = getFirestore();

export default firebase;
