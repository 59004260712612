import React from 'react';

const Logo = props => {
  return (
    <img
      alt="Logo"
      // src="/static/newlogo.svg"
      src="/static/whitehorizontal.svg"
      // style={{maxHeight:'20px !important'}}
      {...props}
    />
  );
};

export default Logo;
