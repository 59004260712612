import React from 'react';

const Logo = props => {
  return (
          <img
            alt="Logo"
            // src="/static/newlogo.svg"
            src="/static/Bridgewhite@0.5x.png"
            // style={{maxHeight:'20px !important'}}
            {...props}
          />
          );
};

export default Logo;
