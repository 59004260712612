import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import firebase from 'src/lib/firebase';

// import { initializeApp } from 'firebase-admin/app';

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  // const auth = getAuth();
  // console.log(firebase.auth().currentUser.uid);

  // firebase.auth().currentUser
  // .setCustomUserClaims('ILzQHXDnNDPLwNTzwFpfOUHluce2', { admin: true })
  // .then(() => {
  //   // The new custom claims will propagate to the user's ID token the
  //   // next time a new one is issued.
  // });

  // var defaultAuth = firebase.auth();

  // defaultAuth.

  // defaultAuth.currentUser.getIdTokenResult()
  // .then((idTokenResult) => {
  //    // Confirm the user is an Admin.
  //    if (!!idTokenResult.claims.admin) {
  //      // Show admin UI.
  //      console.log("admin");
  //    } else {
  //      // Show regular user UI.
  //      console.log("not admin");
  //    }
  // })
  // .catch((error) => {
  //   console.log(error);
  // });

  // firebase.auth()
  // .setCustomUserClaims('ILzQHXDnNDPLwNTzwFpfOUHluce2', { admin: true })
  // .then(() => {
  //   // The new custom claims will propagate to the user's ID token the
  //   // next time a new one is issued.
  // });

  // const auth = getAuth();
  // firebase.auth().currentUser.getIdTokenResult()
  // .then((idTokenResult) => {
  //    // Confirm the user is an Admin.
  //    if (!!idTokenResult.claims.admin) {
  //      // Show admin UI.
  //      console.log("admin");
  //    } else {
  //      // Show regular user UI.
  //      console.log("not admin");
  //    }
  // })
  // .catch((error) => {
  //   console.log(error);
  // });

  // console.log('isAuthenticated - AuthGuard');
  // console.log(isAuthenticated);

  // let user = useAuth();

  // // console.log(useAuth());

  // user.verifyIdToken(user.id)
  // .then((claims) => {
  //   if (claims.admin === true) {
  //     // Allow access to requested admin resource.
  //     console.log(claims);
  //   }
  // });


  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
