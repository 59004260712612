import React, {
  // useState,
  // useEffect,
  // useCallback
} from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// import wait from 'src/utils/wait';

// import firebase from 'src/lib/firebase';

const AdminGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const isMountedRef = useIsMountedRef();
  const user = useAuth();

  // const newUser = firebase.auth().currentUser;
  // if (newUser !== null) {
  //   // The user object has basic properties such as display name, email, etc.
  //   const displayName = newUser.displayName;
  //   const email = newUser.email;
  //   const photoURL = newUser.photoURL;
  //   const emailVerified = newUser.emailVerified;

  //   // The user's ID, unique to the Firebase project. Do NOT use
  //   // this value to authenticate with your backend server, if
  //   // you have one. Use User.getToken() instead.
  //   const uid = user.uid;
  //   const claims = user.customClaims;
    // console.log(user.customClaims);

  // firebase
  //   .auth()
  //   .currentUser.getIdTokenResult()
  //   .then(idTokenResult => {
  //     // Confirm the user is an Admin.
  //     if (!!idTokenResult.claims.admin) {
  //       // Show admin UI.
  //       //showAdminUI();
  //       console.log('admin');
  //       return <>{children}</>;
  //     } else {
  //       // Show regular user UI.
  //       // showRegularUI();
  //       console.log('none admin');
  //       return <Redirect to="/app/account" />;
  //     }
  //   })
  //   .catch(error => {
  //     console.log(error);
  //     return <Redirect to="/app/account" />;
  //   });


  // wait(2000);
  //   // console./log(claims);
  // }

  // console.log(user);

  // var userUID;

  // if(firebase.auth().currentUser){
  // userUID = firebase.auth().currentUser.uid;
  // console.log(user.customClaims);
  // }

  // console.log(isAuthenticated);
  // console.log(isAuthenticated);

  // const getRoles = useCallback(async () => {
  //   try {
  //     // console.log('getroles');
  //     firebase
  //       .auth()
  //       .currentUser.getIdTokenResult()
  //       .then(idTokenResult => {

  //         // var holder = [];
  //         // holder[0] = sections[0];

  //         // // Confirm the user is an Admin.
  //         if (!!idTokenResult.claims.admin) {
  //           // Show admin UI.
  //           //showAdminUI();
  //           console.log('admin');

  //         } else {
  //           // Show regular user UI.
  //           // showRegularUI();
  //           console.log('non admin');
  //           return <Redirect to="/app/account" />;
  //         }

  //         // setSections(holder);

  //       })
  //       .catch(error => {
  //         console.log(error);
  //       });
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [isMountedRef]);

  // useEffect(() => {
  //   getRoles();
  //   // dispatch(getProfile());

  //   // console.log(state.profile);
  // }, [getRoles]);

  // switch (user.user.id) {
  //   case 'ILzQHXDnNDPLwNTzwFpfOUHluce2':
  //     // code block
  //     // console.log('admin detected');
  //     break;
  //   case '64Xwm5GFSIhTnChKTttjy1eGvGg1':
  //     //woot
  //     break;
  //   case 'd8dgtSJSNrVC5K95KOP6xoQa27l2':
  //     break;
  //   // case y:
  //   //   // code block
  //   //   break;
  //   default:
  //     return <Redirect to="/app/account" />;
  //   // code block
  // }

  // console.log('user.customClaims.admin');
  // console.log(user.user.customClaims.admin);
  // if (isAuthenticated) {
  //   return <Redirect to="/app/account" />;
  // }

  if(user.user === undefined){
    console.log('undefined user');
    return <Redirect to="/app/account" />
  }
  if(!user.user.customClaims.admin){
    return <Redirect to="/app/account" />;
  }



  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
