import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
// import axios from 'src/utils/axios';
import axios from 'axios';

// import React, { useState, useEffect, useCallback } from 'react';

// import firebase from 'src/lib/firebase';
// import useAuth from 'src/hooks/useAuth';
// import {
//   // collection,
//   // query,
//   // getDoc,
//   // where,
//   // getDocs,
//   // setDocs,
//   // doc,
//   // addDoc,
//   // setDoc
// } from 'firebase/firestore';
// const admin = require('firebase-admin');

// console.log('getting api');
// await axios.get('https://app.osneycapital.com/api/v1/applications/actions/ILzQHXDnNDPLwNTzwFpfOUHluce2').then(resp => {

//     console.log(resp.data);
// });

// var db = firebase.firestore();

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      // console.log("get events slice");
      // console.log("get events slice");
      // console.log("get events slice");
      // console.log("get events slice");
      // console.log("get events slice");
      // console.log(events);

      state.events = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, _event => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

// const CustomerDetailsView = ({ ...rest }) => {
//   const [user, setUser] = useState(useAuth());
//   console.log(user.id);
//   return (
//     <div/>
//   );
// };

export const getEvents = id => async dispatch => {
  // const response = await axios.get('/api/calendar/events');
  // console.log(User());

  console.log(window.location.host);

  let response = await axios.get(
    'https://app.osneycapital.com/api/v1/applications/actions/' + id
  );
  if (window.location.host !== 'localhost:3000') {
    response = await axios.get('/api/v1/applications/actions/' + id);
  }

  for (let index in response['data']['events']) {
    const unixTime = response['data']['events'][index]['end']['_seconds'];
    const date = new Date(unixTime * 1000);

    response['data']['events'][index]['end'] = date.toISOString();
    response['data']['events'][index]['start'] = date.toISOString();
  }

  // console.log(response.data);
  dispatch(slice.actions.getEvents(response.data));
};

export const createEvent = data => async dispatch => {
  console.log(data);

  const response = await axios.post('/api/calendar/events/new', data);
  // console.log(JSON.stringify(response.data));
  dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = eventId => async dispatch => {
  // console.log("selectEvent eventId");
  // console.log("selectEvent eventId");
  // console.log("selectEvent eventId");
  // console.log(JSON.stringify(eventId));
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async dispatch => {
  const response = await axios.post('/api/calendar/events/update', {
    eventId,
    update
  });

  dispatch(slice.actions.updateEvent(response.data));
};

export const deleteEvent = eventId => async dispatch => {
  await axios.post('/api/calendar/events/remove', {
    eventId
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => dispatch => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openModal = () => dispatch => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => dispatch => {
  dispatch(slice.actions.closeModal());
};

export default slice;
