import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  signInWithMicrosoft: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const signInWithMicrosoft = () => {
    let provider = new firebase.auth.OAuthProvider('microsoft.com');

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        // console.log('user in FirebaseAuthContext');
        // console.log(user);

        // console.log(user);

        user
          .getIdTokenResult()
          .then(idTokenResult => {
            // Confirm the user is an Admin.
            // if (!!idTokenResult.claims.admin) {
            //   // Show admin UI.
            //   //showAdminUI();
            //   console.log('admin');

            // } else {
            //   // Show regular user UI.
            //   // showRegularUI();
            //   console.log('none admin');
            //   return <Redirect to="/app/account" />;
            // }

            let profileTemplate = {
              phone: '',
              team: '',
              runway: '',
              status: '',
              valuation: '',
              value_creation: '',
              tam: '',
              module: '',
              industry: '',
              hiring: true,
              description: '',
              stage: '',
              raised: '',
              url: '',
              slide_deck: '',
              customer: '',
              user: user.id,
              company: '',
              go_to_market: '',
              state: '',
              city: '',
              funding_stage: '',
              revenue: '',
              country: '',
              raising: true,
              applied: true
            };

            const profileHolder = firebase
              .firestore()
              .collection('profiles')
              .doc(user.uid)
              .get()
              .then(doc => {
                if (doc.exists) {
                  // console.log('Document data:', doc.data());

                  let remoteJob = {
                    ...profileTemplate,
                    ...doc.data()
                  };

                  dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                      isAuthenticated: true,
                      user: {
                        id: user.uid,
                        avatar: user.photoURL,
                        email: user.email,
                        name: user.displayName || user.email,
                        emailVerified: user.emailVerified,
                        customClaims: idTokenResult.claims,
                        metadata: user.metadata,
                        phoneNumber: user.phoneNumber,
                        photoURL: user.photoURL,
                        providerId: user.providerId,
                        tier: 'Premium',
                        profile: remoteJob
                      }
                    }
                  });
                } else {
                  // doc.data() will be undefined in this case
                  console.log('No such document!');
                  dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                      isAuthenticated: true,
                      user: {
                        id: user.uid,
                        avatar: user.photoURL,
                        email: user.email,
                        name: user.displayName || user.email,
                        emailVerified: user.emailVerified,
                        customClaims: idTokenResult.claims,
                        metadata: user.metadata,
                        phoneNumber: user.phoneNumber,
                        photoURL: user.photoURL,
                        providerId: user.providerId,
                        tier: 'Premium',
                        profile: profileTemplate
                      }
                    }
                  });
                }
              })
              .catch(error => {
                console.log('Error getting document:', error);
              });
          })
          .catch(error => {
            console.log(error);
            // return <Redirect to="/app/account" />;
          });

        // dispatch({
        //   type: 'AUTH_STATE_CHANGED',
        //   payload: {
        //     isAuthenticated: true,
        //     user: {
        //       id: user.uid,
        //       avatar: user.photoURL,
        //       email: user.email,
        //       name: user.displayName || user.email,
        //       emailVerified: user.emailVerified,
        //       tier: 'Premium',
        //       profile: {
        //         phone: '',
        //         team: '',
        //         runway: '',
        //         status: '',
        //         valuation: '',
        //         value_creation: '',
        //         tam: '',
        //         module: '',
        //         industry: '',
        //         hiring: true,
        //         description: '',
        //         stage: '',
        //         raised: '',
        //         url: '',
        //         slide_deck: '',
        //         customer: '',
        //         user: user.id,
        //         company: '',
        //         go_to_market: '',
        //         state: '',
        //         city: '',
        //         funding_stage: '',
        //         revenue: '',
        //         country: '',
        //         raising: true,
        //         applied: true
        //       }
        //     }
        //   }
        // });

        // console.log('dispatched');
        // console.log(user);
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    // return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        signInWithMicrosoft,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
