import React, {
  useState,
  // useEffect,
  // useCallback
} from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Hidden
} from '@material-ui/core';
import TopBar from './TopBar';
import NavBar from './NavBar';

const useStyles = makeStyles((theme) => ({
  // root: {
  //   backgroundColor: theme.palette.background.default,
  //   display: 'flex',
  //   height: '100%',
  //   overflow: 'hidden',
  //   width: '100%'
  // },
  // wrapper: {
  //   display: 'flex',
  //   flex: '1 1 auto',
  //   overflow: 'hidden',
  //   paddingTop: 100
  // },
  // contentContainer: {
  //   display: 'flex',
  //   flex: '1 1 auto',
  //   overflow: 'hidden'
  // },
  // content: {
  //   flex: '1 1 auto',
  //   height: '100%',
  //   overflow: 'auto'
  // }
}));

const MainLayout = ({children}) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)}/>
      <Hidden smUp>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
        />
      </Hidden>
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
